import { DatabaseDTOItem, DatabaseItem } from './database.model';
import { Channel, ChannelDTO, ChannelType } from './channel.model';
import { Talent, TalentDTO, TalentImage, TalentImageDTO } from './talent.model';
import { Region, RegionDTO } from './region.model';
import { Tag, TagDTO } from './tag.model';

export interface Asset extends DatabaseItem {
  type: string;
  name: string;
  description: string;
  views: number;
  comments: number;
  likes: number;
  thumbnailUrl: string;
  brandId: string;
  brandName: string;
  marketId: string;
  marketName: string;
  marketCode: string;
  viewerUrl: string;
  originalUrl: string;
  regions: Region[];
  // talents: Talent[];
  talentImages: AssetTalentImages[];
  channels: Channel[];
  s3Key: string;
  s3Bucket: string;
  unpublished: Date;
  extension: string;
  status: string;
  activeMarketId: number;
  tags: Tag[];
}

export interface SignedUpload {
  url: string;
  fields: any;
}

export enum AssetTypes {
  Video = 'video',
  Image = 'image'
}

export function getAssetTypeFromGivenType(type: string): AssetTypes {
  return type === 'videoasset' ? AssetTypes.Video : AssetTypes.Image;
}

export enum AssetExtensions {
  JPG = 'jpg',
  PNG = 'png',
  JPEG = 'jpeg',
  MP4 = 'mp4'
}

export interface AssetCreationDTO {
  // We'll only use 1 channel for now, so it's ok to use the enum.
  // The API expects a comma separated list of strings, 
  // so we'll need to make changes if we want to use more than 1 channel.
  channel_types: ChannelType, 
  active_market_id: number,
  extension: AssetExtensions,
  identifier: string,
  url: string,
  name: string,
  thumbnail_url?: string,
}


export interface AssetDTO extends DatabaseDTOItem {
  guid: string;
  type: string;
  extension: string;
  name: string;
  description: string;
  original_url: string;
  view_count: number;
  comment_count: number;
  like_count: number;
  s3_bucket: string;
  s3_key: string;
  status: string;
  analysis_done: true;
  analysis_error_message: string;
  analysis_sf_execution_id: string;
  thumbnail_url: string;
  active_market_id: number;
  brand_id: number;
  brand_name: string;
  market_id: number;
  market_name: string;
  market_code: string;
  viewer_url: string;
  regions: RegionDTO[];
  // talents: TalentDTO[];
  talent_images: AssetTalentImagesDTO[];
  channels: ChannelDTO[];
  date_unpublished?: string;
  tags: TagDTO[];
}


export interface AssetTalentImagesDTO {
  id: number;
  talent_id: number;
  gender: string;
  estimated_age: number;
  dom_facial_feat: string;
  image_url: string;
  is_main_image: boolean;
  role: string;
}

export interface AssetTalentImages {
  id: string;
  talentId: string;
  gender: string;
  estimatedAge: number;
  domFacialFeatures: string;
  imageUrl: string;
  isMainImage: boolean;
  role: string;
}

export interface ExtraItemInfo {
  assetId: string;
  talentId: string;
  role: string;
}

export interface AssetPreviewRequest {
  assetId: string;
  track?: {
    talentId: string;
    assetId: string;
  }
}

export interface BoundingBox {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface AssetTrackingInfoAppearanceDTO {
  timestamp: number;
  bounding_box: BoundingBox;
}

export interface AssetTrackingInfoPersonDTO {
  id: string;
  guid: string;
  appearances: AssetTrackingInfoAppearanceDTO[];
}

export interface AssetTrackingInfoDTO {
  id: string;
  guid: string;
  tracking: AssetTrackingInfoPersonDTO[];
}

export interface AssetTrackingInfo extends AssetTrackingInfoDTO {
}

export type BoxDictionary = {
  [key: string]: BoundingBox;
};
