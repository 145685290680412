import { Component, ChangeDetectionStrategy, Input } from '@angular/core';


@Component({
  selector: 'no-kno-svg-logo-component',
  templateUrl: './svg-logo.component.svg',
  styleUrls: [ './svg-logo.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgLogoComponent {

  @Input() width!: number;
  @Input() height!: number;
  @Input() colors!: { logo?: string; text?: string };

}
