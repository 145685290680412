
<div class="content" *ngIf="data.images.length">
  <!-- <img [src]="data.imageUrl" [alt]="title" class="content-image">-->
  <mat-carousel 
    #carousel
    timings="250ms ease-in"
    maxWidth="auto"
    [hideArrows]="false"
    [hideIndicators]="false"
    [useKeyboard]="true"
    [useMouseWheel]="true"
    [autoplay]="false"
    orientation="ltr"
    [maintainAspectRatio]="false"
    [lazyLoad]="true"
    slideHeight="100%"
  >
    <mat-carousel-slide
      *ngFor="let image of data.images; let i = index;"
      #matCarouselSlide
      [image]="image.imageUrl"
      [hideOverlay]="true"
      [load]="true"
    >
    </mat-carousel-slide>
  </mat-carousel>
</div>

