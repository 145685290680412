import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, OnInit, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { normalize } from '@no-kno/core/helpers/string.helper';

import { LookAlike, Talent, TalentDTO, TalentImage, ReviewStatus, ReviewCode, TalentChangedData, LookalikeMergeRequest, LookalikeMergeDirection, TalentRole, QuickReviewOption } from '@no-kno/core/models/talent.model';
import { AuthorizationService } from '@no-kno/modules/restricted/services/authorization.service';
import { DataService } from '@no-kno/modules/restricted/services/data.service';
import { MatCarouselComponent } from 'ng-mat-carousel';
import { BehaviorSubject, debounceTime, firstValueFrom, of, Subscription, switchMap } from 'rxjs';
import { ConfirmDialogComponent } from '../../dialogs/confirm/confirm.component';

import { AssetTalentImages, ExtraItemInfo } from '@no-kno/core/models/asset.model';
import { deepClone } from '@no-kno/core/helpers/object.helper';
import tooltips from '@no-kno/modules/talent/data/tooltips.json';
import { FilterService } from '@no-kno/modules/restricted/services/filter.service';
import { PreviewService } from '@no-kno/core/services/preview.service';

@Component({
  selector: 'no-kno-display-talent-data',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayTalentDataComponent implements OnInit, OnDestroy, OnChanges {

  LookalikeMergeDirection = LookalikeMergeDirection;
  readonly tooltips = tooltips;
  
  readonly facialFeatures: string[];
  readonly genders: string[];
  readonly roles: { code: string; label: string }[];
  readonly ReviewStatus = ReviewStatus;
  readonly ReviewCode = ReviewCode;
  readonly quickReviewOptions:QuickReviewOption[];


  loading = false;
  talent!: Talent | null;
  edit!: boolean;
  
  extraTalentAttributes!: {
    role: string;
  }
  
  attributes!: {
    gender: string;
    genderConf: number;
    age: number;
    domFacialFeatures: string;
    domFacialFeaturesConfidence: number;
    secFacialFeatures: string;
    secFacialFeaturesConfidence: number;
  };
  mainImage!: TalentImage | null;
  lookalikes!: LookAlike[];
  lookalikesSelected: LookAlike[] = [];
  requestedLookalikeMerges: LookalikeMergeRequest[] = [];
  reviewMessages: string[] = [];

  search!: string;
  query = new BehaviorSubject('');

  private subscription = new Subscription();

  @Input() assets!: TalentImage[];
  @Output() changed = new EventEmitter<TalentChangedData>();
  @Output() deleted = new EventEmitter<string>();
  @Output() closed = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<string>();
  @Input() editMode: number | null = null;
  @Input() showLoader: boolean | null = false;
  @Input() viewContext: 'assets' | 'talents' = 'talents';
  @Input() extraItemInfo: ExtraItemInfo | null = null;

  @ViewChild('carousel') carousel!: MatCarouselComponent;


  @Input() set item(v: Talent | null) {
    this.talent = v;

    const k = () => {
      const assets = [] as TalentImage[];
      this.talent?.images.map(img => {
        if (!assets.find((item: { assetId: string }) => item.assetId === img.assetId)) {
          assets.push(img);
        }
      });

      this.assets = assets;
      this.setReviewMessages();

      this.detector.markForCheck();
    }


    if (this.talent) {
      // Do we have images (and lookalikes) ? If not, get them.
      if (this.talent.images?.length === 0 ?? true) {
          this.loading = true;
          const sub = this.dataService.getTalentById(this.talent.id as string).subscribe({ 
            next: t => {
              if(this.talent && t){
                this.talent.images = t.images;
                this.talent.lookalikes = t.lookalikes;
              }
            }, 
            error: err => {
              console.log(err);
              sub.unsubscribe();
            },
            complete: () => {
              this.loading = false;
              this.reset();
              k();
              sub.unsubscribe();
            }
          })
      } else {
        this.reset();
        k();
      }
    } else {
      this.detector.markForCheck();
    }
  }


  constructor(
    private dialog: MatDialog,
    private detector: ChangeDetectorRef,
    private dataService: DataService,
    public authorization: AuthorizationService,
    private filterService: FilterService,
    private previewService: PreviewService
  ) {

    this.roles = this.filterService.getRoleOptions();
    this.quickReviewOptions = this.filterService.getReviewOptions();
    this.genders = this.filterService.getGenderOptions();
    this.facialFeatures = this.filterService.getFacialFeaturesOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode']) {
      const c = changes['editMode'].currentValue;
      if(c > 0) {
        this.edit = true;
      }
    }
  }

  ngOnInit(): void {

    this.subscription.add(this.query.pipe(
      debounceTime(350),
      switchMap(id => id.length > 0 ? firstValueFrom(this.dataService.getTalentById(id)) : of('empty'))
    ).subscribe(talent => {
      if (talent === 'empty') {
        this.clearSearch();
      } else if (talent && (talent as Talent).mergedIntoId === 0) {
        const t = talent as Talent;

        this.setReviewMessages();

        this.lookalikes = [{
          talentId: t.id as string,
          gender: t.gender,
          estimatedAge: t.estimatedAge,
          domFacialFeatures: t.domFacialFeatures,
          imageUrl: t.imageUrl
        }];
      } else {
        this.lookalikes = [];
      }

      this.detector.markForCheck();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.query.unsubscribe();
  }

  get isLoading(): boolean {
    const answer = this.loading || (this.showLoader ?? false);
    return answer;
  }

  onQuickReviewClick(status: ReviewStatus): void {
    
    if(this.talent?.id){
      const talentId = this.talent.id;
      this.talent.reviewStatus = status;
      const mainImageId = this.mainImage!.id;
      this.changed.emit({ id: talentId, data: { review_status: status }, mainImageId: mainImageId, mergeTalents: [] });
    }
   
  }

  onImageClick(index: number): void {
    this.previewService.requestTalentPreview({ 
      talentId: this.talent!.id as string, 
      imageIndex: index, 
      images: this.talent!.images as TalentImage[] 
    });
  }

  selectMainImage(image: TalentImage) {
    this.mainImage = image;
  }

  mergeLookalike(lookalike: LookAlike, direction: LookalikeMergeDirection) {
    this.lookalikesSelected.push(lookalike);
    this.requestedLookalikeMerges.push({ 
      direction, 
      lookalikeId: lookalike.talentId, 
      talentId: this.talent!.id as string 
    });
  }

  onClose(): void {
    this.closed.emit(true);
  }

  onCancel(): void {
    this.reset();

    this.detector.markForCheck();
  }

  async save(): Promise<void> {
    const data = {
      gender: this.attributes.gender || '',
      gender_confidence: this.attributes.genderConf || 0,
      estimated_age: this.attributes.age ?? 0,
      dom_facial_feat: this.attributes.domFacialFeatures || '',
      dom_facial_feat_confidence: this.attributes.domFacialFeaturesConfidence || 0,
      sec_facial_feat: this.attributes.secFacialFeatures || '',
      sec_facial_feat_confidence: this.attributes.secFacialFeaturesConfidence || 0,
      review_status: ReviewStatus.Reviewed,
    } as Partial<TalentDTO>;

    if (this.talent?.id && this.mainImage?.id) {
      const mainImageId = this.mainImage?.id;

      const reference = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Confirm',
          text: 'Do you want to save changes?'
        },
        disableClose: true
      });

      const confirm = await firstValueFrom(reference.afterClosed());

      if (confirm) {
        this.loading = true;
        this.detector.markForCheck();

        const mergeTalents = [ ...this.requestedLookalikeMerges ];
        this.lookalikesSelected = [];
        this.requestedLookalikeMerges = [];

        const dataToSend:TalentChangedData = { id: this.talent.id, data, mainImageId, mergeTalents }
        if(this.viewContext === 'assets' && this.extraItemInfo){
          const extraInfo = deepClone(this.extraItemInfo) as ExtraItemInfo;
          extraInfo.role = this.extraTalentAttributes.role;
          dataToSend.extraInfo = extraInfo;
        }
        this.changed.emit(dataToSend);
      }
    }
  }

  async delete(): Promise<void> {
    if (this.talent?.id && this.mainImage?.id) {
      const mainImageId = this.mainImage?.id;

      const reference = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Confirm',
          text: 'Do you really want to delete this talent?'
        },
        disableClose: true
      });

      const confirm = await firstValueFrom(reference.afterClosed());

      if (confirm) {
        this.loading = true;
        this.detector.markForCheck();
        this.deleted.emit(this.talent.id);
      }
    }
  }

  setReviewMessages() {
    this.reviewMessages.length = 0;
    if(this.talent?.reviewStatus !== ReviewStatus.WaitingForReview) {
      return;
    }
    const codes = this.talent?.reviewCodes || [];
    const messages: any = {};
    messages[ReviewCode.Angle] = 'Face angle is not optimal';
    messages[ReviewCode.Blur] = 'The face is blurred';
    messages[ReviewCode.ImageSize] = 'The face image is small';
    messages[ReviewCode.AgeConfidence] = 'Low confidence in age prediction';
    messages[ReviewCode.GenderConfidence] =  'Low confidence in gender prediction';
    messages[ReviewCode.EthnicityConfidence] = 'Facial features prediction is inconclusive';

    codes.forEach(code => {
      this.reviewMessages.push(messages[code]);
    });
  }


  refresh(result: Talent): void {
    this.talent = result;
    this.edit = false;
    this.loading = false;

    this.clearSearch();

    this.detector.markForCheck();
  }

  onAssetClick(assetId: string): void {
    this.selected.emit(assetId);
  }

  onTalentTrackerClick(event: Event, talentId: string, assetId: string): void {
    event.stopPropagation();
    console.log(`talent tracker clicked: ${talentId} - ${assetId}`);
    /**
     * - the talent tracker view opens
     * - the talent tab turns to EDIT mode
    */

    this.edit = true; 

    this.previewService.requestAssetPreview({ 
      assetId: assetId,
      track: {
        talentId: talentId,
        assetId: assetId,
      }
    });
  }

  onSearchChange(): void {
    if (this.search) {
      const parsed = normalize(this.search).replace(/[^0-9]/g,'');
      this.query.next(parsed);
    } else {
      this.query.next('');
    }
  }

  clearSearch(reset = false): void {
    this.search = '';

    if (!reset) {
      this.lookalikes = [ ...(this.talent?.lookalikes || []), ...this.lookalikesSelected ];
    } else {
      this.lookalikes = this.talent?.lookalikes || [];
    }
  }

  private reset() {
    this.edit = false;

    if (this.talent) {
      this.attributes = {
        gender: this.talent.gender,
        genderConf: this.talent.genderConfidence,
        age: this.talent.estimatedAge,
        domFacialFeatures: this.talent.domFacialFeatures,
        domFacialFeaturesConfidence: this.talent.domFacialFeaturesConfidence,
        secFacialFeatures: this.talent.secFacialFeatures,
        secFacialFeaturesConfidence: this.talent.secFacialFeaturesConfidence,
      };

      this.clearSearch(true);

      if(this.viewContext === 'assets' && this.extraItemInfo){
        this.extraTalentAttributes = {
          role: this.extraItemInfo.role
        }
      }

      this.mainImage = this.talent.images.find(item => item.isMainImage) || null;
      this.lookalikesSelected = [];
      this.requestedLookalikeMerges = [];
    }
  }
}
