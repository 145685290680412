import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NoknoNotification } from '@no-kno/modules/restricted/models/notification.model';


@Injectable()
export class NotificationService {
  private notificationSubject = new Subject<NoknoNotification>();

  notification$ = this.notificationSubject.asObservable();

  sendNotification(message: NoknoNotification) {
    this.notificationSubject.next(message);
  }
}