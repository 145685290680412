import { Component, Input, OnInit } from '@angular/core';

import { Asset, AssetTypes, getAssetTypeFromGivenType } from '@no-kno/core/models/asset.model';

@Component({
  selector: 'no-kno-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss']
})
export class AssetPreviewComponent implements OnInit {

  isVideo = false;
  @Input() data!: Asset;

  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
    this.isVideo = getAssetTypeFromGivenType(this.data.type) === AssetTypes.Video;
  }

}
