<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">Send {{data}}</h1>
    <button mat-flat-button mat-dialog-close type="button" class="dialog-header-close" >
      <mat-icon class="dialog-header-close-icon" >close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="content">
    <mat-form-field appearance="fill" class="content-input-title">
      <mat-label>Title</mat-label>
      <input matInput [(ngModel)]="title">
    </mat-form-field>

    <mat-form-field appearance="fill" class="content-input-description">
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="8" cdkAutosizeMaxRows="10" [(ngModel)]="message"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button mat-flat-button class="actions-button" (click)="onClickSend()">
      <span class="button-text actions-button-text">SEND {{data}}</span>
    </button>
  </mat-dialog-actions>
</div>