import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'no-kno-edit-buttons',
    templateUrl: './edit-buttons.component.html',
    styleUrls: ['./edit-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditButtonsComponent {

  @Input() loading = false;
  @Output() canceled = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();


  constructor(
  ) {
  }

  onCancel(): void {
    this.canceled.emit(true);
  }

  onSave(): void {
    console.log('onSave');
    this.saved.emit(true);
  }

}
