import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';

import { map, Observable } from 'rxjs';
import { updateDoc } from 'firebase/firestore';

import { UserData } from '@no-kno/core/models/user.model';
import { normalize } from '@no-kno/core/helpers/database.helper';
import { TenantService } from '@no-kno/core/services/tenant.service';


@Injectable()
export class UsersService {

  constructor(
    private firestore: Firestore,
    private tenantService: TenantService
  ) {
  }

  getUser(id: string): Observable<UserData | null> {
    const path = `customers/${this.tenantService.customer}/users`;
    const ref = doc(this.firestore, path, id);

    return docData(ref).pipe(map(data => (data ? ({ id, ...data }) as UserData : null)));
  }

  async createUser(id: string, data: UserData): Promise<boolean> {
    try {
      const copy = normalize<UserData>(data as UserData, false);
      const path = `customers/${this.tenantService.customer}/users`;

      await setDoc(doc(this.firestore, `${path}/${id}`), copy);

      return true;
    } catch (err) {
      console.log('{users.servie, createUser()}', err);
    }

    return false;
  }

  async updateUser(id: string, data: Partial<UserData>): Promise<boolean> {
    try {
      const copy = normalize<UserData>(data as UserData, true);
      const path = `customers/${this.tenantService.customer}/users`;

      await updateDoc(doc(this.firestore, `${path}/${id}`), copy as Partial<UserData>);

      return true;
    } catch (err) {
      console.log('{users.servie, updateUser()}', err);
    }

    return false;
  }

}
