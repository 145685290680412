import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, signInWithEmailAndPassword, signOut, user, UserCredential, sendPasswordResetEmail } from '@angular/fire/auth';

import { BehaviorSubject, combineLatest, filter, firstValueFrom, from, map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';

import { UserData } from '@no-kno/core/models/user.model';

import { UsersService } from './users.service';
import { TenantService } from '@no-kno/core/services/tenant.service';


@Injectable()
export class AuthenticationService {

  redirect = false;
  user$: Observable<UserData | null>;

  private loading$ = new BehaviorSubject<boolean>(false);


  constructor(
    private auth: Auth,
    private userService: UsersService,
    private router: Router,
    private tenantService: TenantService,
  ) {
    this.user$ = combineLatest([ this.loading$, user(this.auth) ]).pipe(
      filter(([ loading, usr ]) => !loading),
      switchMap(([ loading, usr ]) => usr ? this.userService.getUser(usr.uid as string) : of(null)),
      switchMap((usr: UserData | null) => this.checkUser(usr)),
      tap(usr => {
        if (this.redirect) {
          this.redirect = false;
          this.router.navigate(['']);
        }
      }),
      shareReplay(1)
    );
  }

  async login(email: string, password: string): Promise<boolean> {
    try {
      const customerId = this.tenantService.customer;
      const customer = await firstValueFrom(this.tenantService.getCustomerData(customerId));

      if (customer?.tenantId) {
        this.auth.tenantId = customer.tenantId;
        const credential = await signInWithEmailAndPassword(this.auth, email, password);

        return await this.prepare(credential);
      }

      return false;
    } catch (error) {
      console.log('{AuthenticationService, login()}:' , error);

      return false;
    }
  };

  async resetPassword(email: string): Promise<boolean> {
    const customerId = this.tenantService.customer;
    const customer = await firstValueFrom(this.tenantService.getCustomerData(customerId));

    if (customer?.tenantId) {
      this.auth.tenantId = customer.tenantId;
    }

    return sendPasswordResetEmail(this.auth, email)
      .then(() => console.log('Email sent!'))
      .then(() => true);
  }

  async logout() {
    return await signOut(this.auth);
  }

  getToken(): Observable<string | null> {
    if (this.auth.currentUser) {
      return from(this.auth.currentUser.getIdToken()).pipe(
        take(1),
        map(token => token || null)
      );
    }

    return of(null);
  }


  private async prepare(credential: UserCredential): Promise<boolean> {
    try {
      this.loading$.next(true);

      if (credential) {
        const status = await this.check(credential);

        if (!status) {
          await this.logout();
        } else {
          this.redirect = true;
        }

        this.loading$.next(false);

        return status;
      }
    } catch (err) {
      console.log('{AuthenticationService, prepare()}', err);
    }

    return false;
  }

  private async check(credential: UserCredential): Promise<boolean> {
    const usr = credential.user;

    if (usr) {
      const persisted = await firstValueFrom(this.userService.getUser(usr.uid));

      const data = {
        name: usr.displayName ? usr.displayName : null,
        email: usr.email,
        photo: usr.photoURL ? usr.photoURL : null,
        provider: usr.providerId,
        enabled: persisted && !persisted.enabled ? persisted?.enabled : true,
        claims: persisted && 'claims' in persisted ? !!persisted.claims : true,
        consent: false
      } as UserData ;

      if (persisted) {
        return await this.userService.updateUser(usr.uid, data);
      }

      return await this.userService.createUser(usr.uid, data);
    }

    return false;
  }

  private async checkUser(usr: UserData | null): Promise<UserData | null> {
    if (!usr) {
      return null;
    } else {
      if (!usr.enabled) {
        this.logout();
        this.router.navigate(['login']);
        return null;
      } else {
        if ('token' in usr && usr.token) {
          if (usr.id) {
            await this.token(usr.id);
          }
        }
      }
    }

    return usr;
  }

  private async token(id: string): Promise<void> {
    const usr = this.auth.currentUser;

    await usr?.getIdToken(true);
    await this.userService.updateUser(id, { token: false });
  }

}
