import { Pipe, PipeTransform } from '@angular/core';

import { ChannelType } from '@no-kno/core/models/channel.model';

const MAP = {
  [ChannelType.Youtube]: 'Youtube',
  [ChannelType.Facebook]: 'Facebook',
  [ChannelType.Instagram]: 'Instagram',
  [ChannelType.AudienceNetwork]: 'Audience Network',
  [ChannelType.Messenger]: 'Messenger',
  [ChannelType.Website]: 'Website',
  [ChannelType.FacebookOrganic]: 'Organic Facebook',
  [ChannelType.InstagramOrganic]: 'Organic Instagram',
  [ChannelType.Email]: 'Email',
  [ChannelType.Television]: 'Television',
  [ChannelType.OnlineVideo]: 'Online Video',
  [ChannelType.Print]: 'Print',
  [ChannelType.YoutubeAds]: 'Youtube Ads',
  [ChannelType.GoogleDisplayNetwork]: 'Google Display Network',
  [ChannelType.Programmatic]: 'Programmatic',
  [ChannelType.LinkedInAds]: 'LinkedIn Ads',
  [ChannelType.LinkedInOrganic]: 'Organic LinkedIn',
};

@Pipe({
  name: 'channel'
})
export class ChannelPipe implements PipeTransform {

  transform(origin: ChannelType | string): string {
    if (origin in MAP) {
      return (MAP as any)[origin];
    }

    return origin;
  }

}
