import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { deepClone } from '@no-kno/core/helpers/object.helper';

import { CustomDateType, FilterData, FilterViews } from '@no-kno/modules/restricted/models/filter.model';
import { QuickReviewOption, ReviewStatus, TalentRole } from '@no-kno/core/models/talent.model';


interface OnCompleteFunction {
  (brands:number[], markets:number[]) : void;
}

const FILTERS_DEFAULT = {
  brands: [],
  regions: [],
  markets: [],
  channels: [],
  tags: [],
  date: {
    start: null,
    end: null,
  },
} as FilterData;


@Injectable()
export class FilterService {

  view$ = new BehaviorSubject<FilterViews>(FilterViews.Filter);
  filters$ = new BehaviorSubject<FilterData>(FILTERS_DEFAULT);
  isShowFilters$ = new BehaviorSubject<boolean>(true);
  multiSelectMarket$ = new BehaviorSubject<boolean>(true);
  multiSelectBrand$ = new BehaviorSubject<boolean>(true);

  private references = {} as { [region: string]: string[] };


  constructor() {
  }

  getGenderOptions(): string[] {
    return ['Male', 'Female', 'Nonbinary'];
  }

  getFacialFeaturesOptions(): string[] {
    return [ 'A1', 'B', 'A2', 'W1', 'W2', 'L' ];
  }

  getReviewOptions(): QuickReviewOption[] {
    return [
      { code: ReviewStatus.Reviewed, label: 'Reviewed' },
      { code: ReviewStatus.WaitingForReview, label: 'Waiting for review' },
      { code: ReviewStatus.NoReviewNeeded, label: 'No review needed'}
    ]
  }

  getMetricsRoleOptions(): { code: string; label: string }[] {
    return [
      { code: 'foreground', label: 'Foreground' },
      { code: 'background', label: 'Background' },
      { code: 'main', label: 'Main' },
      { code: 'support', label: 'Support' },
    ];
  }

  getRoleOptions(): { code: string; label: string }[] {
    return [
      { code: TalentRole.Main, label: 'Main' },
      { code: TalentRole.Support, label: 'Support' },
      { code: TalentRole.Extra, label: 'Extra' },
      { code: TalentRole.Unknown, label: 'Unknown' },
    ];
  }


  enableBrandAndMarketMultiSelect(): void {
    this.multiSelectMarket$.next(true);
    this.multiSelectBrand$.next(true);
  }

  disableBrandAndMarketMultiSelect(): void {
    this.multiSelectMarket$.next(false);
    this.multiSelectBrand$.next(false);
  }

  changeView(view: FilterViews = FilterViews.Filter): void {
    this.view$.next(view);
    if(view === FilterViews.Settings){
      this.isShowFilters$.next(true);
    }
  }

  hideFilter(): void {
    this.isShowFilters$.next(false);
  }

  toggleFilterVisibility(): void {
    this.isShowFilters$.next(!this.isShowFilters$.getValue());
  }

  changeBrands(id: string, onComplete?: OnCompleteFunction): void {
    const current = deepClone(this.filters$.getValue());
    const index = current.brands.indexOf(id);

    if (!this.multiSelectBrand$.getValue()) {
      if (current.brands.length === 1 && index === 0) {
        current.brands = [];
      } else {
        current.brands = [ id ];
      }
    } else {
      if (current.brands.includes(id)) {
        current.brands.splice(index, 1);
      } else {
        current.brands.push(id);
      }
    }

    this.filters$.next(current);

    if(onComplete) {
      onComplete(current.brands, current.markets);
    }
  }

  changeRegions(id: string, markets: string[], onComplete?: OnCompleteFunction): void {
    const current = deepClone(this.filters$.getValue());

    if (!(id in this.references)) {
      this.references[id] = markets;
    }

    if (current.regions.includes(id)) {
      const index = current.regions.indexOf(id);
      current.regions.splice(index, 1);

      delete this.references[id];

      for (const market of markets) {
        const f = new Set([ ...Object.keys(this.references).reduce((prev, curr) => [ ...prev, ...this.references[curr] ], [] as string[]).flat() ]);
        const i = current.markets.indexOf(market);

        if (!f.has(market) && i !== -1) {
          current.markets.splice(i, 1);
        }
      }
    } else {
      current.regions.push(id);

      for (const market of markets) {
        if (!current.markets.includes(market)) {
          current.markets.push(market);
        }
      }
    }

    this.filters$.next(current);

    if(onComplete) {
      onComplete(current.brands, current.markets);
    }
  }

  changeMarkets(id: string, onComplete?: OnCompleteFunction): void {
    const current = deepClone(this.filters$.getValue());
    const index = current.markets.indexOf(id);

    if (!this.multiSelectMarket$.getValue()) {
      if (current.markets.length === 1 && index === 0) {
        current.markets = [];
      } else {
        current.markets = [ id ];
      }
    } else {
      if (index !== -1) {
        current.markets.splice(index, 1);
      } else {
        current.markets.push(id);
      }
    }

    this.filters$.next(current);

    if(onComplete) {
      onComplete(current.brands, current.markets);
    }
  }

  changeChannels(value: string): void {
    const current = deepClone(this.filters$.getValue());

    if (current.channels.includes(value)) {
      const index = current.channels.indexOf(value);
      current.channels.splice(index, 1);
    } else {
      current.channels.push(value);
    }

    this.filters$.next(current);
  }

  changeTags(value: string): void {
    const current = deepClone(this.filters$.getValue());

    if (current.tags.includes(value)) {
      const index = current.tags.indexOf(value);
      current.tags.splice(index, 1);
    } else {
      current.tags.push(value);
    }

    this.filters$.next(current);
  }

  changeDate(value: { start: Date | null; end: Date | null }): void {
    const current = deepClone(this.filters$.getValue());
    current.date = value;

    this.filters$.next(current);
  }

  changeSpecificDate(value: Date, type: CustomDateType): void {
    const current = deepClone(this.filters$.getValue());
    current.date[type] = value;
    this.filters$.next(current);
  }

  clearFilter(): void {
    this.filters$.next(deepClone(FILTERS_DEFAULT));
  }

}
