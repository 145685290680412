<div class="container">
  <div class="time-info" *ngIf="currentTime > 0">
    {{ (currentTime + 1) * 1000 | date:'mm:ss' }}
  </div>
  <div #containerRef class="canvas-container">
    <canvas #canvasRef (click)="onCanvasClicked($event)" style="z-index: 1"></canvas>
    <canvas #timeIndicatorRef (click)="onCanvasClicked($event)" style="z-index: 2;"></canvas>
  </div>
</div>

