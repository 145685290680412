import { Injectable } from '@angular/core';
import {  TalentSelected } from '@no-kno/core/models/talent.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TalentSelectionService {

  readonly talentImage$ = new BehaviorSubject<TalentSelected|null>(null);

  selectTalentImage(talentSelected: TalentSelected) {
    console.log('talentSelected', talentSelected);
    this.talentImage$.next(talentSelected);
  }

}
