import { Injectable } from '@angular/core';
import { TalentPreviewRequest } from '@no-kno/core/models/talent.model';
import { BehaviorSubject } from 'rxjs';
import { FilterService } from '../../modules/restricted/services/filter.service';
import { AssetPreviewRequest } from '../models/asset.model';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  readonly request$ = new BehaviorSubject<TalentPreviewRequest|AssetPreviewRequest|null>(null);

  constructor(private filterService: FilterService) { }

  requestTalentPreview(request: TalentPreviewRequest) {
    this.request$.next(request);
    this.filterService.hideFilter();
  }

  refreshPreview() {
    if(this.request$.value){
      this.request$.next(this.request$.value);
    }
  }

  requestAssetPreview(request: AssetPreviewRequest) {
    this.request$.next(request);
    this.filterService.hideFilter();
  }

  clearPreview() {
    this.request$.next(null);
  }
}
