import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { HelpAndFeedbackFormsComponent } from '../../forms/form-send-feedback/form-send-feedback.component';


@Component({
  selector: 'no-kno-help-and-feedback-dialog',
  templateUrl: './help-and-feedback.component.html',
  styleUrls: ['./help-and-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpAndFeedbackDialogComponent {

  formType = {
    QUESTION: 'question',
    FEEDBACK: 'feedback'
  };

  constructor(public dialog: MatDialog) { }

  openForm(type: string): void {
    if (type === this.formType.FEEDBACK) {
      this.dialog.open(HelpAndFeedbackFormsComponent, {
        width: '482px',
        data: 'feedback'
      });
    } else {
      this.dialog.open(HelpAndFeedbackFormsComponent, {
        width: '482px',
        data: 'question'
      });
    }
  }

}
