export const normalize = (original: string): string => {
  const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz      ';
  const p = new RegExp(a.split('').join('|'), 'g');

  return ('' + original)
    .toString()
    .toLowerCase()
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/\s+/g, ' ')
    .replace(/[^\w\s]+/g, '');
};
