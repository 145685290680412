<div class="wrapper" [ngClass]="single? 'wrapper-single': 'wrapper-standard'">
  <div class="header" [class.is-open]="open" [ngClass]="single? 'header-single': 'header-standard'">
    <h3 class="mat-h2 header-title">{{ title }}</h3>
    <div class="header-arrow-wrapper" (click)="open = !open">
      <mat-icon class="header-arrow-icon" color="primary">expand_more</mat-icon>
    </div>
  </div>
  <div class="container" [@collapse]="{value: !open, params: animationParams}" [ngClass]="single? 'container-single': 'container-standard'">
    <ng-content></ng-content>
  </div>
</div>