import { ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { collapseAnimation } from './filters-section.animations';

@Component({
    selector: 'no-kno-filters-section',
    templateUrl: './filters-section.component.html',
    styleUrls: ['./filters-section.component.scss'],
    animations: [ collapseAnimation ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersSectionComponent implements OnInit {

  @Input() title!: string;
  @Input() single = false;
  open = false;
  animationParams = {paddingBottom: '16px'};

  constructor() {
  }

  ngOnInit(): void {
    this.animationParams.paddingBottom = this.single ? '0px' : '16px';
  }

}
