import { DatabaseDTOItem, DatabaseItem } from './database.model';

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
  Developer = 'developer'
}

export interface UserData extends DatabaseItem {
  name: string;
  email: string;
  enabled: boolean;
  token?: boolean;
  photo?: string;
  provider?: string;
  claims?: boolean;
  consent?: boolean;
  roles?: string[];
}

export interface UserInfo extends DatabaseItem {
  name: string;
  email: string;
  firebaseId: string;
  customerId: string;
  activeMarkets: { id: number; brandId: number; marketId: number }[];
}

export interface UserInfoDTO extends DatabaseDTOItem {
  firebase_id: string;
  name: string;
  email: string;
  customer_id: number;
  active_markets: { id: number; brand_id: number; market_id: number }[];
}
