<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">Cookie Policy</h1>
  </div>

  <mat-dialog-content class="dialog-content">
    <div class="dialog-content-text">
      Please read and accept the Terms and Conditions and the Privacy Policy before continuing.
    </div>

    <div class="dialog-content-checkbox">
      <mat-checkbox [(ngModel)]="terms" [disabled]="this.data"><a href="https://nokno.co/no-kno-platform-terms-of-use/" target="_blank" [class.disable]="this.data">Terms of Use</a></mat-checkbox>
      <mat-checkbox [(ngModel)]="cookies" [disabled]="this.data"><a href="https://nokno.co/no-kno-platform-privacy-policy" target="_blank" [class.disable]="this.data">Privacy Policy</a></mat-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button mat-flat-button mat-dialog-close class="actions-button" [disabled]="!cookies || !terms" (click)="onClose(true)">
      <mat-icon class="button-icon">done</mat-icon>
      <span class="button-text">ACCEPT</span>
    </button>
  </mat-dialog-actions>
</div>
