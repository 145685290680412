import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'views'
})
export class ViewsPipe implements PipeTransform {

  transform(origin: number): string {
    if (origin > 999 && origin < 999999) {
      return (origin / 1000).toFixed() + 'k';
    } else if (origin > 999999) {
      return (origin / 1000000).toFixed() + 'M';
    }

    return origin.toString();
  }
}
