<no-kno-loader minHeight="160px" *ngIf="isLoading"></no-kno-loader>

<div *ngIf="errorMessage">{{errorMessage}}</div>

<div class="content" #containerRef>
  <div class="asset-content" *ngIf="isVideo else image">
    <video #videoRef 
      [src]="data.viewerUrl" 
      class="content-video" 
      (play)="onVideoPlayed()" 
      (pause)="onVideoPaused()"
      (ended)="onVideoEnded()"
      (timeupdate)="onVideoTimeUpdate($event)"
      controls   
      [style.display]="isLoading ? 'none' : ''">
    </video>
    <canvas #canvasRef class="overlay" [style.display]="isLoading ? 'none' : ''"></canvas>
  </div>
  
 
  <no-kno-timeline-canvas *ngIf="!isLoading && !errorMessage"
    [data]="trackingData.tracking"
    [personRoles]="personRoles"
    [currentTime]="videoCurrentTime"
    (select)="onTimelineItemSelected($event)"
    (timelineClick)="onTimelineClick($event)"
    [maxWidth]="containerWidth"
  ></no-kno-timeline-canvas>
  
</div>

<ng-template #image>
  <canvas #canvasRef [style.display]="isLoading ? 'none' : ''"></canvas>
</ng-template>
