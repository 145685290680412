import { Injectable } from '@angular/core';

import { Observable, shareReplay } from 'rxjs';

import { Asset } from '@no-kno/core/models/asset.model';
import { PagedData } from '@no-kno/core/models/api.model';

import { FilterData } from '../models/filter.model';
import { ApiService } from './api.service';
import { Talent, TalentsFilter } from '@no-kno/core/models/talent.model';
import { Tag } from '@no-kno/core/models/tag.model';


@Injectable()
export class DataService {

  channels$ = this.api.getChannels().pipe(shareReplay(1));
  markets$ = this.api.getMarkets().pipe(shareReplay(1));
  brands$ = this.api.getBrands().pipe(shareReplay(1));
  regions$ = this.api.getRegions().pipe(shareReplay(1));


  constructor(private api: ApiService) {
  }


  getAssets(filter?: FilterData, sort: string = '', page: number = 1, size: number = 50, search:string = ''): Observable<PagedData<Asset[]>> {
    return this.api.getAssets(filter || {} as FilterData, sort, page + 1, size, search);
  }

  getAssetById(id: string): Observable<Asset> {
    return this.api.getAssetById(id);
  }

  getTalents(filter?: FilterData, sort: string = '', page: number = 1, size: number = 50, extraFilter: TalentsFilter = {}): Observable<PagedData<Talent[]>> {
    return this.api.getTalents(filter || {} as FilterData, sort, page + 1, size, extraFilter);
  }

  getTalentById(id: string): Observable<Talent | null> {
    return this.api.getTalentById(id);
  }

  searchTags(query: string): Observable<Tag[]> {
    return this.api.getTags(query);
  }
}
