import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, getLocaleId, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { environment } from '@no-kno/env/environment';
import { SharedModule } from '@no-kno/shared/shared.module';

import { FirebaseService } from './services/firebase.service';
import { TenantService } from './services/tenant.service';

import enGB from '@angular/common/locales/en-GB';
import nlBE from '@angular/common/locales/nl-BE';
import nl from '@angular/common/locales/nl';
import de from '@angular/common/locales/de';
import frBE from '@angular/common/locales/fr-BE';
import fr from '@angular/common/locales/fr';
import pt from '@angular/common/locales/pt';

const routes = [
  {
    path: '',
    loadChildren: () => import('@no-kno/modules/restricted/restricted.module').then(m => m.RestrictedModule)
  }
];

const firebase = [
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideAuth(() => getAuth()),
  provideFirestore(() => getFirestore()),
];

const getLocale = () => {
  switch (navigator.language) {
    case 'en-GB':
      registerLocaleData(enGB);
      return navigator.language;
    case 'nl':
    case 'nl-NL':
      registerLocaleData(nl);
      return navigator.language;
    case 'nl-BE':
      registerLocaleData(nlBE);
      return navigator.language;
    case 'fr-FR':
      registerLocaleData(fr);
      return navigator.language;
    case 'fr-BE':
      registerLocaleData(frBE);
      return navigator.language;
    case 'de-DE':
      registerLocaleData(de);
      return navigator.language;
    case 'pt-BR':
    case 'pt-PT':
      registerLocaleData(pt);
      return navigator.language;
    default :
      return 'en-US';
  }
};


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    // BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    AngularSvgIconModule.forRoot(),
    ...firebase
  ],
  providers: [
    FirebaseService,
    TenantService,
    { provide: LOCALE_ID, useValue: getLocale() },
  ]
})
export class CoreModule {
}
