import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject } from '@angular/core';

import { addDoc, Firestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantService } from '@no-kno/core/services/tenant.service';
import { AuthenticationService } from '@no-kno/modules/restricted/services/authentication.service';
import { collection } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'no-kno-form-send-feedback',
  templateUrl: './form-send-feedback.component.html',
  styleUrls: ['./form-send-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpAndFeedbackFormsComponent {

  title = '';
  message = '';
  @Input() userId!: string;

  constructor(
    private snackBar: MatSnackBar,
    private firestore: Firestore,
    private authService: AuthenticationService,
    private tenantService: TenantService,
    private dialog: MatDialogRef<HelpAndFeedbackFormsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
  }

  async onClickSend(): Promise<void> {
    const user = await firstValueFrom(this.authService.user$);
    const customer = this.tenantService.customer;

    if (user && customer && this.title.length > 0 && this.message.length > 0) {
      const data = {
        userId: user.id,
        type: this.data,
        title:this.title,
        message: this.message,
        date: new Date(),
        customer
      };

      try {
       const ref = collection(this.firestore, 'help');
       await addDoc(ref, data);

       this.reset();
      } catch (error) {
        const message = 'Not sent - Something wrong';
        const action = 'Close';

        this.snackBar.open(message, action);

        console.log('{Help and Feedback form} Error: ', error);
      }

    } else {
      const message = 'Invalid fields';
      const action = 'Close';

      this.snackBar.open(message, action);
    }

    this.dialog.close();
  }


  private reset(): void {
    this.message = '';
    this.title = '';
  }
}
