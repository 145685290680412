import { Asset, AssetDTO, AssetTalentImages, AssetTalentImagesDTO } from '@no-kno/core/models/asset.model';
import { LookAlike, LookAlikeDTO, Talent, TalentDTO, TalentImageDTO, TalentImage, ReviewStatus, ReviewCode } from '@no-kno/core/models/talent.model';
import { Channel, ChannelDTO } from '@no-kno/core/models/channel.model';
import { Region, RegionDTO } from '@no-kno/core/models/region.model';
import { Market, MarketDTO } from '@no-kno/core/models/market.model';
import { Brand, BrandDTO } from '@no-kno/core/models/brand.model';
import { PagedResponse, PageStatus } from '@no-kno/core/models/api.model';
import { CustomChart, CustomChartDTO } from '@no-kno/core/models/custom-charts.model';
import { UserInfo, UserInfoDTO } from '@no-kno/core/models/user.model';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';

const defaultAssetThumbnailUrl = '/assets/images/white-square.png';
export const parseAssets = (item: AssetDTO): Asset => {
  const asset = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    published: item.date_published ? new Date(item.date_published) : new Date(),
    unpublished: item.date_unpublished ? new Date(item.date_unpublished) : null,
    type: item.type ?? '',
    name: item.name ?? '',
    description: item.description ?? '',
    views: item.view_count ?? 0,
    comments: item.like_count ?? 0,
    likes: item.like_count ?? 0,
    thumbnailUrl: item.thumbnail_url && item.thumbnail_url!='None' ? item.thumbnail_url : defaultAssetThumbnailUrl,
    brandId: item.brand_id?.toString() ?? '',
    brandName: item.brand_name ?? '',
    marketId: item.market_id?.toString() ?? '',
    marketName: item.market_name ?? '',
    marketCode: item.market_code ?? '',
    viewerUrl: item.viewer_url ?? '',
    originalUrl: item.original_url ?? '',
    s3Bucket: item.s3_bucket ?? '',
    s3Key: item.s3_key ?? '',
    extension: item.extension ?? '',
    status: item.status ?? '',
    activeMarketId: item.active_market_id || null,
    regions: item.regions ? item.regions.map(region => parseRegions(region)) : [],
    // talents: item.talents ? item.talents.map(talent => parseTalents(talent)) : [],
    talentImages: item.talent_images ? item.talent_images.map(talentImages => parseAssetsTalentImages(talentImages)) : [],
    channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
    tags: item.tags ? item.tags.map(tag => parseTags(tag)).sort((a, b) => (a.name > b.name) ? 1 : -1) : [],
  } as Asset;

  return asset;
};

export const parseAssetsTalentImages = (item: AssetTalentImagesDTO): AssetTalentImages => {
  const talentImages = {
    id: item.id.toString() ?? '',
    talentId: item.talent_id.toString() ?? '',
    gender: item.gender ?? '',
    estimatedAge: item.estimated_age || 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    imageUrl: item.image_url || '',
    isMainImage: item.is_main_image || false,
    role: item.role ?? 'unknown'
  } as AssetTalentImages;

  return talentImages;
};

export const parsePagination = (item: PagedResponse<any>): PageStatus => {
  const status = {
    current: item.current_page,
    size: item.items_per_page,
    pages: item.total_number_of_pages,
    total: item.total_number_of_items
  } as PageStatus;

  return status;
};

export const parseTalents = (item: TalentDTO): Talent => {
  const talent = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    original: item.is_original ?? '',
    assetId: item.asset_id?.toString() ?? '',
    gender: item.gender ?? '',
    minimumAge: item.minimum_age ?? 0,
    maximumAge: item.maximum_age ?? 0,
    // age: ((item.minimum_age + item.maximum_age) / 2).toFixed(),
    // race: item.race ?? '',
    description: item.description ?? '',
    imageUrl: item.images ? setTalentMainImage(item.images) : item.main_image_url? item.main_image_url : '',
    lookalikes: item.lookalikes ? item.lookalikes.map(lookalike => parseLookALike(lookalike)) : [],
    // asset: item.asset? parseTalentAsset(item.asset) : null,
    genderConfidence: item.gender_confidence ?? 0,
    estimatedAge: item.estimated_age ?? 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    domFacialFeaturesConfidence: item.dom_facial_feat_confidence ?? 0,
    secFacialFeatures: item.sec_facial_feat ?? '',
    secFacialFeaturesConfidence: item.sec_facial_feat_confidence ?? 0,
    images: item.images ? item.images.map(image => parseTalentImage(image)) : [],
    mergedIntoId: item.merged_into_id ?? 0,
    extraInfo: item.extra_info ?? {},
    reviewStatus: item.review_status,
    reviewCodes: item.review_codes ?? [],
    roles: item.roles ?? []
  } as Talent;

  return talent;
};

export const parseChannels = (item: ChannelDTO): Channel => {
  const channel = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    name: item.name ?? '',
    type: item.type ?? '',
    customerId: item.customer_id ?? null
  } as Channel;

  return channel;
};

export const parseRegions = (item: RegionDTO): Region => {
  const region = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    parentId: item.parent_id?.toString() ?? '',
    name: item.name ?? '',
    code: item.code ?? ''
  } as Region;

  return region;
};

export const parseTags = (item: TagDTO): Tag => {
  const tag = {
    id: item.id.toString(),
    customerId: item.customer_id?.toString() ?? '',
    name: item.name ?? ''
  } as Tag; 
  
  return tag;
}

export const parseLookALike = (item: LookAlikeDTO): LookAlike => {
  const lookalike = {
    talentId: item.talent_id?.toString() ?? '',
    gender: item.gender ?? '',
    estimatedAge: item.estimated_age || 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    imageUrl: item.image_url ?? '',
  } as LookAlike;

  return lookalike;
};

// export const parseTalentAsset = (item: TalentAssetDTO): TalentAsset => {
//   const talentAsset = {
//     assetId: item.id?.toString() ?? '',
//     assetThumbnailUrl: item.thumbnail_url ?? '',
//     views: item.views ?? 0,
//     channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
//   } as TalentAsset;

//   return talentAsset;
// };

export const parseTalentImage = (item: TalentImageDTO): TalentImage => {
  const talentImage = {
    id: item.id?.toString() ?? '',
    assetId: item.asset_id?.toString() ?? '',
    talentId: item.talent_id?.toString() ?? '',
    assetThumbnailUrl: item.asset_thumbnail_url ?? '',
    imageUrl: item.image_url ?? '',
    isMainImage: item.is_main_image || false,
    views: item.views ?? 0,
    channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
    marketId: item.market_id ? item.market_id.toString() : '',
    marketName: item.market_name ?? ''
  } as TalentImage;

  return talentImage;
};

export const parseMarkets = (item: MarketDTO): Market => {
  const market = {
    id: item.id.toString(),
    brandId: item.brand_id?.toString() ?? '',
    name: item.name ?? '',
    regions: item.regions ? item.regions.map(region => parseRegions(region)) : []
  } as Market;

  return market;
};

export const parseBrands = (item: BrandDTO): Brand => {
  const brand = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    name: item.name ?? '',
    markets: item.markets ? item.markets.map(market => parseMarkets(market)) : []
  } as Brand;

  return brand;
};

export const parseCustomCharts = (item: CustomChartDTO): CustomChart => {
  const arr = item.title.split('::');

  return  {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    title: arr.length === 2 ? arr[1] : item.title,
    category: arr.length === 2 ? arr[0] : 'No Category',
    activeMarketId: item.active_market_id.toString()
  } as CustomChart;
};

export const parseUser = (item: UserInfoDTO): UserInfo => {
  const user = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    firebaseId: item.firebase_id?.toString() ?? '',
    name: item.name ?? '',
    email: item.email ?? '',
    activeMarkets: item.active_markets ? item.active_markets.map(market => ({
      id: market.id,
      brandId: market.brand_id,
      marketId: market.market_id
    })) : []
  } as UserInfo;

  return user;
};

export const organizeCustomCharts = (list: CustomChart[]): { [category: string]: CustomChart[] } => list.reduce((prev, curr) => {
  if (!(curr.category in prev)) {
    prev[curr.category] = [];
  }

  prev[curr.category].push(curr);

  return prev;
}, {} as { [category: string]: CustomChart[] });


const setTalentMainImage = (images: TalentImageDTO[]): string => {
  const mainImage = images.find(img => img.is_main_image);

  return mainImage?.image_url || '';
};
