<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">Help and Feedback</h1>
    <button mat-flat-button mat-dialog-close type="button" class="dialog-header-close" >
      <mat-icon class="dialog-header-close-icon" >close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content">
    <div>
      Your feedback is invaluable to us as we continue to improve and refine our product.<br/><br/>
      We encourage you to share your experiences with us, whether it's to clarify any doubts you might have or to suggest improvements.<br/>
      We truly appreciate your help in making our product the best it can be.
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button mat-flat-button mat-dialog-close class="actions-button" (click)="openForm(formType.QUESTION)">
      <mat-icon class="button-icon">help_outline</mat-icon>
      <span class="button-text">ASK QUESTION</span>
    </button>
    <button mat-flat-button mat-dialog-close class="actions-button" (click)="openForm(formType.FEEDBACK)">
      <mat-icon class="button-icon"> chat_bubble_outline</mat-icon>
      <span class="button-text">SEND FEEDBACK</span>
    </button>
  </mat-dialog-actions>
</div>