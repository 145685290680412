import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AssetTrackingInfo, AssetTrackingInfoPersonDTO, BoundingBox, BoxDictionary } from '@no-kno/core/models/asset.model';
import { getRoleColor } from '@no-kno/core/models/talent.model';
import { TalentSelectionService } from '@no-kno/modules/restricted/services/talent-selection.service';
import { ta } from 'date-fns/locale';
import { Subscription } from 'rxjs';


const LABEL_COLUMN_WIDTH = 100;
const CELL_HEIGHT = 20;
const CHART_ROW_BACKGROUND_COLOR = '#F46363';
const CHART_ROW_SELECTED_BACKGROUND_COLOR = '#F1C40F';
const TALENT_LABEL_PREFIX = 'Talent ';

interface TimelineBox {
  talentId: string;
  start:number;
  box: BoundingBox;
}

@Component({
  selector: 'no-kno-timeline-canvas',
  templateUrl: './timeline-canvas.component.html',
  styleUrls: ['./timeline-canvas.component.scss']
})
export class TimelineCanvasComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() columns:any[] = [];
  @Input() data!:AssetTrackingInfoPersonDTO[];
  @Input() personRoles: { [key: string]: string } = {};
  @Input() maxHeight:number = 200;
  @Input() maxWidth:number = 0;
  @Input() currentTime:number = 0;
  @Output() select = new EventEmitter<TimelineBox>();
  @Output() timelineClick = new EventEmitter<Number>();
  private selectedTalentId: string = '';
  private subscription = new Subscription();
  private isDrawing = false;
  private containerDiv!: HTMLDivElement;


  @ViewChild("canvasRef", { static: false }) canvasRef!: ElementRef;
  private context!: CanvasRenderingContext2D;
  private canvasElement: any;
  @ViewChild("timeIndicatorRef", { static: false }) timeIndicatorRef!: ElementRef;
  private timeIndicatorContext!: CanvasRenderingContext2D;
  private timeIndicatorCanvasElement: any;
  @ViewChild("containerRef", { static: false }) containerRef!: ElementRef;
  

  private timelineData: any[] = [];
  private maxSeconds: number = 0;
  private rowColors = ['#f1f3f4', '#ffffff'];
  private currentBoxes: TimelineBox[] = [];

  constructor(private talentSelectionService: TalentSelectionService) { }
  
  ngAfterViewInit(): void {
    this.canvasElement = this.canvasRef.nativeElement;
    this.timeIndicatorCanvasElement = this.timeIndicatorRef.nativeElement;
    this.context = this.canvasElement.getContext("2d");
    this.timeIndicatorContext = this.timeIndicatorCanvasElement.getContext("2d");
    this.prepareData();
    this.drawCanvas();

    this.subscription.add(this.talentSelectionService.talentImage$.subscribe(async data => {
      if(data?.talentId !== this.selectedTalentId){
        console.log('talentId changed', data!.talentId);
        this.selectedTalentId = data!.talentId;
        this.drawCanvas();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['currentTime']){
      this.drawTimeIndicator();
    }
  }


  prepareData() {
    const timelineData:any[] = [];
    let maxSeconds = 0;

    this.data.forEach((person) => {
      const secondsHandled:number[] = [];
      const label = `${TALENT_LABEL_PREFIX }${person.id}`;
      let currentGroup:any[] = [];
      person.appearances.forEach((app) => {
        const seconds = Math.floor(app.timestamp / 1000);
        if(!secondsHandled.includes(seconds)){
          secondsHandled.push(seconds);
          if(seconds + 1 > maxSeconds) {
            maxSeconds = seconds + 1;
          }
          const obj = [label, seconds, seconds + 1];
          currentGroup.push(obj);
        }
      });
      timelineData.push(currentGroup);
    });

    this.timelineData = timelineData;
    this.maxSeconds = maxSeconds;
    console.log('Timeline data:', this.timelineData);
    console.log('Max seconds:', this.maxSeconds);
  }

  drawCanvas() {
    if(this.isDrawing) {
      return;
    }
    this.isDrawing = true;
    this.context.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    let currentTimeCellIndex = -1;
    const numRows = this.timelineData.length;
    const numCols = this.maxSeconds + 1;
    const cellWidth = Math.ceil(this.maxWidth * 0.7 / numCols);
    console.log('Max width:', this.maxWidth);
    console.log('Cell width:', cellWidth);
    // Adjust the canvas size if necessary
    this.canvasElement.width = ((numCols-1) * cellWidth) + LABEL_COLUMN_WIDTH;
    this.canvasElement.height = numRows * CELL_HEIGHT;
    this.timeIndicatorCanvasElement.width = this.canvasElement.width;
    this.timeIndicatorCanvasElement.height = this.canvasElement.height;
    this.containerDiv = this.containerRef.nativeElement;
    this.containerDiv.style.height = `${this.canvasElement.height}px`;
    this.containerDiv.style.width = `${this.canvasElement.width}px`;

    let currentBoxes: TimelineBox[] = [];

    for (let row = 0; row < numRows; row++) {
      const label = this.timelineData[row][0][0];
      const talentId = label.replace(TALENT_LABEL_PREFIX , '');
      const roleColor = this.personRoles[talentId];
      for (let col = 0; col < numCols; col++) {
        
        this.context.fillStyle = row % 2 === 0? this.rowColors[1] : this.rowColors[0];
        if(talentId === this.selectedTalentId) {
          this.context.fillStyle = CHART_ROW_SELECTED_BACKGROUND_COLOR;
        }
        const cw = col === 0 ? LABEL_COLUMN_WIDTH : cellWidth;
        const x = col === 0 ? 0 : (((col-1) * cw) + LABEL_COLUMN_WIDTH);

        this.context.fillRect(x, row * CELL_HEIGHT, cw, CELL_HEIGHT);

        // If there's data, we should change the color
        if(col > 0) {
          const ct = col-1;
          const isPresent = this.timelineData[row].find((el: any) => el[1] >= ct && el[2] <= ct+1);
          if(isPresent) {
            this.context.fillStyle = getRoleColor(roleColor);
            this.context.fillRect(x, (row * CELL_HEIGHT) + 4, cw, CELL_HEIGHT - 8);
            currentBoxes.push({ 
              talentId, 
              start: ct,
              box: { 
                top: row * CELL_HEIGHT, 
                left: x, 
                width: cw, 
                height: CELL_HEIGHT 
              } 
            });
          }
        }

        if (col === 0) {
          this.context.fillStyle = "#000"; // Text color
          this.context.font = "10px Arial";
          this.context.fillText(label, col + 10, (row + 1) * CELL_HEIGHT - 6); // Adjust text positioning as needed
        }
      }
    }

    this.currentBoxes = currentBoxes;
    this.isDrawing = false;
  }

  drawTimeIndicator() {
    if(!this.timeIndicatorContext){
      return;
    }

    this.timeIndicatorContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    if(this.currentTime > 0){
      const numRows = this.timelineData.length;
      const numCols = this.maxSeconds + 1;
      const cellWidth = Math.ceil(this.maxWidth * 0.7 / numCols);
      let x = LABEL_COLUMN_WIDTH;
      const currentTimeCellIndex = Math.floor(this.currentTime);
      x += (currentTimeCellIndex * cellWidth);
      let height = this.canvasElement.height;

      const lineWidth = 2;
      this.timeIndicatorContext.lineWidth = lineWidth; // Set the thickness of the border
      this.timeIndicatorContext.strokeStyle = '#666666'; // Set the color of the border
      this.timeIndicatorContext.strokeRect(x, lineWidth, cellWidth, height - (lineWidth + 1));
    }
  }

  onCanvasClicked(e: MouseEvent) {
    console.log('Canvas clicked', e);
    //@ts-ignore
    var rect = e.target?.getBoundingClientRect();
    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };

    let selectedBox: TimelineBox | undefined;
    for (let i = 0; i < this.currentBoxes.length; i++) {
      const cb = this.currentBoxes[i];
      const box = cb.box;
      if(pos.x >= box.left && pos.x <= box.left + box.width && pos.y >= box.top && pos.y <= box.top + box.height){
        console.log('talent selected:', cb.talentId);
        selectedBox = cb;
        break;
      }
    }

    if(selectedBox){
      console.log('talent selected:', selectedBox);
      this.selectedTalentId = selectedBox.talentId;
      this.select.emit(selectedBox);
      this.drawCanvas();
    } else {
      console.info('No talent selected, just assume time is requested');
      const numCols = this.maxSeconds + 1;
      const cellWidth = Math.ceil(this.maxWidth * 0.7 / numCols);
      const secs = (pos.x - LABEL_COLUMN_WIDTH) / cellWidth;
      
      if(secs >= 0){
        const roundedSecs = Math.round(secs * 100) / 100;
        console.log('Time selected:', roundedSecs);
        this.timelineClick.emit(roundedSecs);
      }
    }
  }

}
