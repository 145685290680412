<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">Upload asset</h1>
    <button mat-flat-button mat-dialog-close type="button" class="dialog-header-close" >
      <mat-icon class="dialog-header-close-icon" >close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="content">

    <no-kno-file-upload fileExt="jpg,jpeg,png,mp4" accept=".jpg,.jpeg,.png,.mp4" (changeFile)="onFileSelect($event)"></no-kno-file-upload>

    <span>Or you can enter a YouTube url:</span>
    <mat-form-field appearance="fill" class="content-input-youtube">
      <mat-label>YouTube</mat-label>
      <input matInput [(ngModel)]="youtubeUrl" placeholder="Ex: https://www.youtube.com/watch?v=8Pj-YEQbojk">
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions class="actions" *ngIf="!loading">
    <button mat-flat-button class="actions-button" (click)="onClickUpload()" [disabled]="!(file || youtubeUrl)">
      <span class="button-text actions-button-text">UPLOAD</span>
    </button>
  </mat-dialog-actions>
  
  <no-kno-loader *ngIf="loading"></no-kno-loader>

</div>