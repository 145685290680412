
<div *ngIf="!isLoading else loading">
  <div class="top">
    <h3 class="top-title" tabindex="1">{{title}}</h3>

    <div>
      <button (click)="onClose()" class="button edit">
        <mat-icon class="button-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <no-kno-asset-preview *ngIf="(mode == PreviewMode.ASSET) && assetData && !isShowTracker" [data]="assetData!.asset"></no-kno-asset-preview>
  <no-kno-talent-preview *ngIf="(mode == PreviewMode.TALENT) && talentData" [data]="talentData!"></no-kno-talent-preview>
  <no-kno-asset-tracker *ngIf="(mode == PreviewMode.ASSET) && assetData && isShowTracker" [data]="assetData!.asset" [trackingInfo]="trackingInfo!"></no-kno-asset-tracker>
</div>

<ng-template #loading>
  <no-kno-loader minHeight="160px"></no-kno-loader>
</ng-template>