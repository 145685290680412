import { ExtraItemInfo } from './asset.model';
import { Channel, ChannelDTO } from './channel.model';
import { DatabaseDTOItem, DatabaseItem } from './database.model';

export enum ReviewStatus {
  NoReviewNeeded = 'no_review_needed',
  WaitingForReview = 'waiting_for_review',
  Reviewed = 'reviewed'
}

export enum ReviewCode {
  Angle = 'ANGLE', 
  Blur = 'BLUR', 
  ImageSize = 'IMG_SIZE', 
  AgeConfidence = 'CONF_AGE', 
  GenderConfidence = 'CONF_GEN', 
  EthnicityConfidence = 'CONF_ETH'
}

export enum TalentRole {
  Main = 'main',
  Support = 'support',
  Extra = 'extra',
  Unknown = 'unknown'
}

export enum RoleColors {
  Main = '#C04D4F',
  Support = '#E67E22',
  Extra = '#2ECC71',
  Unknown =  '#34495E'
}

export const getRoleColor = (role: string) => {
  switch(role) {
    case TalentRole.Main:
      return RoleColors.Main;
    case TalentRole.Support:
      return RoleColors.Support;
    case TalentRole.Extra:
      return RoleColors.Extra;
    default:
      return RoleColors.Unknown;
  }
}

export interface Talent extends DatabaseItem {
  original?: boolean;
  assetId: string;
  gender: string;
  minimumAge: number;
  maximumAge: number;
  // race: string;
  description: string;
  imageUrl: string;
  lookalikes: LookAlike[];
  // asset: TalentAsset;
  genderConfidence: number;
  estimatedAge: number;
  domFacialFeatures: string;
  domFacialFeaturesConfidence: number;
  secFacialFeatures: string;
  secFacialFeaturesConfidence: number;
  images: TalentImage[];
  mergedIntoId: number;
  extraInfo: object;
  reviewStatus: ReviewStatus;
  reviewCodes: ReviewCode[];
  roles: TalentRole[];
}

export interface TalentDTO extends DatabaseDTOItem {
  guid: string;
  is_original: boolean;
  asset_id: number;
  gender: string;
  minimum_age: number;
  maximum_age: number;
  // race: string;
  description: string;
  //image_url: string;
  main_image_url: string;
  // asset?: TalentAssetDTO;
  lookalikes?: LookAlikeDTO[];
  gender_confidence: number;
  estimated_age: number;
  dom_facial_feat: string;
  dom_facial_feat_confidence: number;
  sec_facial_feat: string;
  sec_facial_feat_confidence: number;
  images: TalentImageDTO[];
  merged_into_id: number;
  extra_info?: object;
  review_status: ReviewStatus;
  review_codes: ReviewCode[];
  roles?: TalentRole[];
}

// export interface TalentAssetDTO {
//   id: number;
//   thumbnail_url: string;
//   views: number;
//   channels: ChannelDTO[];
// }

// export interface TalentAsset {
//   id: string;
//   talentId: string;
//   assetId: string;
//   imageUrl: string;
//   views: number;
//   channels: Channel[];
// }



export interface TalentImageDTO {
  id: number;
  asset_id: number;
  talent_id: number;
  asset_thumbnail_url: string;
  image_url: string;
  is_main_image: true;
  views: number;
  channels: ChannelDTO[];
  market_id: number;
  market_name: string;
}

export interface TalentImage {
  id: string;
  assetId: string;
  talentId: string;
  assetThumbnailUrl: string;
  imageUrl: string;
  isMainImage: boolean;
  views: number;
  channels: Channel[];
  marketId: string;
  marketName: string;
}

export interface LookAlikeDTO {
  talent_id: number;
  gender: string;
  estimated_age: number;
  dom_facial_feat: string;
  image_url: string;
}

export interface LookAlike {
  talentId: string;
  gender: string;
  estimatedAge: number;
  domFacialFeatures: string;
  imageUrl: string;
}

export enum LookalikeMergeDirection {
  MergeAsChild = 'merge_as_child',
  MergeAsParent = 'merge_as_parent'
}

export interface LookalikeMergeRequest {
  direction: LookalikeMergeDirection;
  lookalikeId: string;
  talentId: string;
}

export interface TalentChangedData { 
  id: string; 
  data: Partial<TalentDTO>; 
  mainImageId: string; 
  mergeTalents: LookalikeMergeRequest[]; 
  extraInfo?: ExtraItemInfo;
}

export interface TalentsFilter {
  search?: string;
  role?: string;
  facialFeatures?: string;
  age?: string;
  gender?: string;
  reviewStatus?: string;
  bodyType?: string;
}

export interface QuickReviewOption {
  code: ReviewStatus;
  label: string;
}

export interface TalentPreviewRequest {
  talentId: string;
  imageIndex: number;
  images: TalentImage[];
}

export interface TalentSelected {
  talentId: string;
  action: 'edit' | 'view';
  role: string;
}

