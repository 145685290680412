// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  customer: {
    regex: /^([a-z0-9\-]+)\.(2na8|nokno)\.(local\:4200|dev|co)$/g,
    contact: 'gava@2na8.com'
  },
  settings: {
    facebook: {
      endpoint: 'https://dev-connector-api.nokno.co/facebook/lambda',
      appId: '508721150642212',
      version: 'v17.0'
    },
  },
  api: {
    baseUrl: 'https://dev-api.nokno.co/api/v1'
  },
  firebase: {
    projectId: 'no-kno-dev',
    appId: '1:559312486566:web:bfb264404265479fc37467',
    storageBucket: 'no-kno-dev.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyC3KSC8o5khNuIpBd54J5X7y5fH9r8Dlk8',
    authDomain: 'no-kno-dev.firebaseapp.com',
    messagingSenderId: '559312486566',
    measurementId: 'G-LY1424RQYE',
  },
  charts: {
    colors: [ '#3498DB', '#C04D4F', '#9B59B6', '#F1C40F', '#E67E22', '#34495E', '#F46363', '#463B4F', '#2ECC71' ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
