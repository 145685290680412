import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@no-kno/modules/restricted/services/api.service';
import { TenantService } from '@no-kno/core/services/tenant.service';
import { NotificationService } from '@no-kno/modules/restricted/services/notification.service';

import { AssetCreationDTO, AssetExtensions, SignedUpload } from '@no-kno/core/models/asset.model';

//@ts-ignore
import * as uuid from 'uuid';
import { HttpClient } from '@angular/common/http';
import { NoknoNotificationTypes } from '@no-kno/modules/restricted/models/notification.model';


@Component({
  selector: 'no-kno-upload-asset-form',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadAssetFormComponent implements OnDestroy {

  youtubeUrl = '';
  file: File | undefined;
  loading = false;
  private subscription = new Subscription();
  

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private http: HttpClient,
    public tenantService: TenantService,
    private snackBar: MatSnackBar,
    private dialog: MatDialogRef<UploadAssetFormComponent>,
    private detector: ChangeDetectorRef
  ) {
  }

  onFileSelect(files: Array<File>) {
    console.log('files selected', files);
    if (files.length > 0) {
      this.file = files[0];
    }
  }

  private uploadFile(signedUpload: SignedUpload, file: File): Observable<any> {

    const multipartParams = signedUpload.fields;

    const data = new FormData();
    Object.keys(multipartParams).forEach(key => {
      data.append(key, multipartParams[key]);
    });
    // data.append('contentType', file.type);
    data.append('file', file, file.name);

    return this.http.post(signedUpload.url, data);
  }

  private async sendDataToAPI(data: AssetCreationDTO): Promise<void> {
    
    const result = await firstValueFrom(this.apiService.createAsset(data)).catch((e) => {
      console.error(e);
    });

    if (result) {
      console.log('Asset created', result);
      this.snackBar.open('Asset created', 'Close');
      this.dialog.close();
      this.notificationService.sendNotification({
        subject: NoknoNotificationTypes.AssetCreated,
        payload: {
          asset: result
        }
      });

    } else {
      this.snackBar.open('Error creating asset', 'Close');
    }

    this.loading = false;
    this.detector.markForCheck();
  }

  async onClickUpload(): Promise<void> {
    this.loading = true;
    this.detector.markForCheck();
    
    const customerId = this.tenantService.customer;
    const customer = await firstValueFrom(this.tenantService.getCustomerData(customerId));
    if (!((customer?.defaultActiveMarketId ?? 0 > 0) && customer?.defaultChannel)) {
      this.snackBar.open('Invalid customer settings', 'Close');
      this.dialog.close();
      this.loading = false;
      return;
    }

    const identifier = uuid.v4();

    const data: AssetCreationDTO = {
      channel_types: customer!.defaultChannel!,
      active_market_id: customer!.defaultActiveMarketId!,
      identifier: identifier,
      extension: AssetExtensions.JPEG,
      url: '',
      name: ''
    };

    if (this.file) {
      data.extension = this.file.name.split('.').pop()?.toLowerCase() as AssetExtensions;
      data.name = this.file.name;

      const uploadForm = await firstValueFrom(this.apiService.getSignedUpload(identifier, data.extension))
        .catch((e) => {
          console.error(e);
        });

      if (!uploadForm) {
        this.snackBar.open('Error getting signed upload', 'Close');
        return;
      }

      this.subscription.add(this.uploadFile(uploadForm, this.file).subscribe({
        next: (v) => {
          console.log('next', v);
        },
        error: (e) => {
          console.error(e);
          this.snackBar.open('Error uploading file', 'Close');
          this.loading = false;
        },
        complete: async () => {
          const fileUrl = `${uploadForm.url}/${uploadForm.fields.key}`;
          console.log('complete', fileUrl);
          data.url = fileUrl;
          if(data.extension != AssetExtensions.MP4){
            data.thumbnail_url = fileUrl;
          }
          await this.sendDataToAPI(data);
        }
      }));

    } else if (this.youtubeUrl) {
      data.extension = AssetExtensions.MP4;
      data.url = this.youtubeUrl;
      data.name = `${identifier}.mp4`;
      await this.sendDataToAPI(data);
    } else {
      console.error('No file or youtube url');
      this.loading = false;
      this.snackBar.open('No file or youtube url', 'Close');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
