import { Pipe, PipeTransform } from '@angular/core';

const capitalizeFirstLetterAndLowercaseRest = (str:string) => {
  if (!str) return '';
  if(str == 'unknown') return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

@Pipe({
  name: 'roles'
})
export class RolesPipe implements PipeTransform {

  transform(roles: string[]): string {
    roles.forEach((role, index, arr) => arr[index] = capitalizeFirstLetterAndLowercaseRest(role))
    const fr = roles.filter(role => role)
    if(fr.length > 0) {
      return fr.join(', ')
    } else {
      return '-'
    }
  }

}
