import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, Observable, of, throwError } from 'rxjs';

import { format } from 'date-fns';

import { parseAssets, parseBrands, parseChannels, parseCustomCharts, parseMarkets, parsePagination, parseRegions, parseTalentImage, parseTalents, parseTags, parseUser } from '@no-kno/core/helpers/parse.helper';
import { Asset, AssetCreationDTO, AssetDTO, AssetTrackingInfo, AssetTrackingInfoDTO, SignedUpload } from '@no-kno/core/models/asset.model';
import { Talent, TalentDTO, TalentImage, TalentImageDTO, TalentsFilter } from '@no-kno/core/models/talent.model';
import { Channel, ChannelDTO } from '@no-kno/core/models/channel.model';
import { Market, MarketDTO } from '@no-kno/core/models/market.model';
import { Brand, BrandDTO } from '@no-kno/core/models/brand.model';
import { Region, RegionDTO } from '@no-kno/core/models/region.model';
import { PagedData, PagedResponse } from '@no-kno/core/models/api.model';
import { CustomChart, CustomChartDTO } from '@no-kno/core/models/custom-charts.model';
import { UserInfo, UserInfoDTO } from '@no-kno/core/models/user.model';
import { ChartApiType } from '@no-kno/core/models/chart.model';

import { FilterData } from '@no-kno/modules/restricted/models/filter.model';

import { environment } from '@no-kno/env/environment';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';
import { ca } from 'date-fns/locale';


@Injectable()
export class ApiService {

  constructor(private http: HttpClient) {
  }


  getUserInfo(): Observable<UserInfo> {
    const url = `${environment.api.baseUrl}/users/me`;

    return this.http.get<UserInfoDTO>(url).pipe(
      map(json => parseUser(json))
    );
  }

  getAssets(filter: FilterData, sort: string, page: number, size: number, search: string = ''): Observable<PagedData<Asset[]>> {
    const url = `${environment.api.baseUrl}/assets/all/detailed`;
    const params = this.getParameters(filter, sort, page, size, search);

    return this.http.get<PagedResponse<AssetDTO>>(url, { params }).pipe(
      map(json => ({
        page: parsePagination(json),
        items: json.items.map(asset => parseAssets(asset))
      }))
    );
  }

  getAssetById(id: string): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    return this.http.get<AssetDTO>(url).pipe(
      map(asset => parseAssets(asset))
    );
  }

  
  getSignedUpload(identifier: string, extension: string): Observable<SignedUpload> {
    const url = `${environment.api.baseUrl}/assets/signed-upload?identifier=${identifier}&extension=${extension}`;
    return this.http.get<SignedUpload>(url);
  }

  createAsset(data: AssetCreationDTO): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets`;
    return this.http.post<AssetDTO>(url, data).pipe(
      map(json => parseAssets(json))
    );
  };

  deleteAsset(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  updateAsset(id: string, data: Partial<AssetDTO>): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    const tagsJoined = data.tags?.map(tag => tag.name).join(',') ?? '';

    return this.http.put<AssetDTO>(url, { tags: tagsJoined}).pipe(
      map(json => parseAssets(json))
    );
  }

  private addTalentsFilterParams(params: HttpParams, filter: TalentsFilter): HttpParams {
    if(filter.role) {
      params = params = params.append('role', filter.role);
    }
    if(filter.gender) {
      params = params = params.append('gender', filter.gender);
    }
    if(filter.age) {
      params = params = params.append('age', filter.age);
    }
    if(filter.reviewStatus) {
      params = params = params.append('review_status', filter.reviewStatus);
    }
    if(filter.facialFeatures) {
      params = params = params.append('dominant_facial_features', filter.facialFeatures);
    }
    return params;
  }

  getTalents(filter: FilterData, sort: string, page: number, size: number, extraFilter: TalentsFilter = {}): Observable<PagedData<Talent[]>> {
    const url = `${environment.api.baseUrl}/talents`;
    let params = this.getParameters(filter, sort, page, size, extraFilter?.search ?? '');
    params = this.addTalentsFilterParams(params, extraFilter);

    return this.http.get<PagedResponse<TalentDTO>>(url, { params }).pipe(
      map(json => ({
        page: parsePagination(json),
        items: json.items.map(talent => parseTalents(talent))
      }))
    );
  }

  getTalentById(id: string): Observable<Talent | null> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.get<TalentDTO>(url).pipe(
      map(talent => parseTalents(talent)),
      catchError(() => of(null))
    );
  }

  setTalentsAttributes(id: string, data: Partial<TalentDTO>): Observable<Talent> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.put<TalentDTO>(url, data).pipe(
      map(json => parseTalents(json))
    );
  }

  mergeTalent(fromId: string, toId: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talents/${fromId}/merge`;

    return this.http.put(url, { merge_into_id: parseInt(toId, 10) }, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  deleteTalent(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  setTalentMainImage(id: string): Observable<TalentImage> {
    const url = `${environment.api.baseUrl}/talent_images/${id}/promote`;

    return this.http.put<TalentImageDTO>(url, null).pipe(
      map(json => parseTalentImage(json))
    );
  }

  setTalentRole(id: string, assetId: string, role: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talent_images/talent_attributes`;
    const data = { role, asset_id: parseInt(assetId, 10), talent_id: parseInt(id, 10) };
    
    return this.http.put(url, data, { observe: 'response' }).pipe(
      map(response => response.status === 204)
    );
  }

  getChannels(): Observable<Channel[]> {
    const url = `${environment.api.baseUrl}/channels`;

    return this.http.get<PagedResponse<ChannelDTO>>(url).pipe(
      map(json => json.items.map(channel => parseChannels(channel)))
    );
  }

  getMarkets(): Observable<Market[]> {
    const url = `${environment.api.baseUrl}/markets`;

    return this.http.get<PagedResponse<MarketDTO>>(url).pipe(
      map(json => json.items.map(market => parseMarkets(market)))
    );
  }

  getBrands(): Observable<Brand[]> {
    const url = `${environment.api.baseUrl}/brands?per_page=1000`;

    return this.http.get<PagedResponse<BrandDTO>>(url).pipe(
      map(json => json.items.map(brand => parseBrands(brand)))
    );
  }

  getTags(searchString: string, markets: number[] = [], brands: number[] = []): Observable<Tag[]> {
    const url = `${environment.api.baseUrl}/tags`;
    let params = new HttpParams()
    if(searchString) {
      params = params.append('search', searchString);
    }

    if(markets.length > 0) {
      params = params.append('markets', markets.join(','));
    }
    if(brands.length > 0) {
      params = params.append('brands', brands.join(','));
    }
    
    return this.http.get<PagedResponse<TagDTO>>(url, {params}).pipe(
      map(json => json.items.map(tag => parseTags(tag)))
    );
  }

  getRegions(): Observable<Region[]> {
    const url = `${environment.api.baseUrl}/regions`;

    return this.http.get<PagedResponse<RegionDTO>>(url).pipe(
      map(json => json.items.map(region => parseRegions(region)))
    );
  }

  getChart(type: string, feature: string, params: any): Observable<any> {
    const url = `${environment.api.baseUrl}/charts/${type}/${feature}`;
    let searchParams = new HttpParams();
    if (params.date?.start) {
      const startDate = format(params.date.start, 'yyyy-MM-dd');
      searchParams = searchParams.append('min_date', startDate);
    }
    if (params.date?.end) {
      const endDate = format(params.date.end, 'yyyy-MM-dd');
      searchParams = searchParams.append('max_date', endDate);
    }
    if (params.brands.length > 0) {
      if( type === ChartApiType.ObjectivesDistribution) {
        // objectives charts can only be generated for one brand at a time,
        // because an active market = link between a brand and a market
        searchParams = searchParams.append('brands', params.brands[0]);
      } else {
        searchParams = searchParams.append('brands', params.brands.join(','));
      }
    }
    if (params.channels.length > 0) {
      searchParams = searchParams.append('channels', params.channels.join(','));
    }
    if (params.criterion) {
      searchParams = searchParams.append('criterion', params.criterion);
    }

    if (params.tags.length > 0) {
      searchParams = searchParams.append('tags', params.tags.join(','));
    }

    if(params.role && params.role !== 'all') {
      searchParams = searchParams.append('role', params.role);
    }

    if( type === ChartApiType.ObjectivesDistribution) {
      // objectives charts can only be generated for one market at a time,
      // because an active market = link between a brand and a market
      if (params.markets.length > 0) {
        searchParams = searchParams.append('markets', params.markets[0]);
      } else {
        return throwError(() => new Error('No market selected'));
      }
    } else if (params.markets.length > 0) {
      searchParams = searchParams.append('markets', params.markets.join(','));
    }

    return this.http.get<any>(url, { params: searchParams });
  }

  getCustomChart(): Observable<CustomChart[]> {
    const url = `${environment.api.baseUrl}/custom_charts`;

    return this.http.get<PagedResponse<CustomChartDTO>>(url).pipe(
      map(json => json.items.map(chart => parseCustomCharts(chart)))
    );
  }

  getAssetTrackingInfo(id: string): Observable<AssetTrackingInfo|null> {
    const url = `${environment.api.baseUrl}/assets/${id}/people-tracking`;

    return this.http.get<AssetTrackingInfoDTO>(url).pipe(
      map(info => info as AssetTrackingInfo),
      catchError(() => of(null))
    );
  }


  private getParameters(filter: FilterData, sort: string, page: number, size: number, search:string): HttpParams {
    let params = new HttpParams()
      .append('page', page)
      .append('per_page', size);

    if (sort) {
      params = params.append('sort', sort.replace(':asc', ''));
    }

    if (filter) {
      if (filter.markets.length > 0) {
        params = params.append('markets', filter.markets.join(','));
      }

      if (filter.brands.length > 0) {
        params = params.append('brands', filter.brands.join(','));
      }

      if (filter.channels.length > 0) {
        params = params.append('channel_types', filter.channels.join(','));
      }

      if (filter.tags.length > 0) {
        params = params.append('tags', filter.tags.join(','));
      }

      if (filter.date && filter.date.start && filter.date.end) {
        params = params.append('start_date', format(filter.date.start, 'yyyy-MM-dd'));
        params = params.append('end_date', format(filter.date.end, 'yyyy-MM-dd'));
      }
    }

    if(search) {
      params = params.append('search', search);
    }

    return params;
  }

}
