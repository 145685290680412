<div *ngIf="asset" class="card-wrapper">
  <div class="card">
    <div class="card-header">
      <span class="card-header-title">{{asset.id}}</span>
      
      <div class="card-header-buttons">
        <button *ngIf="authorization.isEditor$ | async" type="button" class="card-header-buttons-button edit" (click)="edit = !edit"><mat-icon class="card-header-buttons-button-icon">edit</mat-icon></button>
        <button type="button" class="card-header-buttons-button" (click)="onClose()"><mat-icon>close</mat-icon></button>
      </div>
    </div>

    <button class="card-image-wrapper" (click)="onImageClick()">
      <img [src]="asset.thumbnailUrl" class="card-image" alt="">
      <span class="card-image-text">View Asset</span>
    </button>

    <ng-container *ngIf="!edit">
      <div class="card-info">
        
        <div *ngIf="authorization.isDeveloper$ | async" class="card-info-text">
          <span class="card-info-text-title">Status</span>
          <span class="card-info-text-description">{{asset.status}}</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Name</span>
          <span class="card-info-text-description">{{asset.name}}</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Description</span>
          <span class="card-info-text-description">{{ asset.description }} </span>
          <span *ngIf="!asset.description" class="card-info-text-description">-</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Brand</span>
          <span class="card-info-text-description">{{asset.brandName}}</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Market</span>
          <span class="card-info-text-description">{{asset.marketName}}</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Channel</span>
          <div class="card-info-text-channels">
            <ng-container *ngFor="let item of asset.channels">
              <svg-icon [src]="'/assets/svg/icon-' + item.type.replace('channel', '') + '.svg'" class="card-info-text-channels-icon"
                  [matTooltip]="item.type | channel"></svg-icon>
            </ng-container>
          </div>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Views</span>
          <ng-container *ngIf="asset.views > 0; else nAn">
            <span class="card-info-text-description">{{asset.views | views}}</span>
          </ng-container>
          <ng-template #nAn>
            <!-- If we don't know the number of views (custom source) leave it empty, don't use 0 -->
            -
          </ng-template>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Publish Date</span>
          <span class="card-info-text-description">{{asset.published | date: 'shortDate'}}</span>
          <span *ngIf="!asset.published" class="card-info-text-description">-</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Unpublish Date</span>
          <span class="card-info-text-description">{{asset.unpublished | date: 'shortDate'}}</span>
          <span *ngIf="!asset.unpublished" class="card-info-text-description">-</span>
        </div>

        <div class="card-info-text">
          <span class="card-info-text-title">Tags</span>
          <div class="card-info-text-tags">
            <ng-container *ngIf="asset.tags.length > 0; else noTags">
                <ng-container *ngFor="let item of asset.tags">
                  <span class="card-info-text-description">{{item.name}}</span>
                </ng-container>
            </ng-container>
            <ng-template #noTags>
              <!-- No tags found -->
              -
            </ng-template>
          </div>
        </div>
      </div>

      <div class="card-details" *ngIf="asset.talentImages && asset.talentImages.length > 0">
        <span class="card-details-title">Talents</span>

        <div class="card-details-content-wrapper">
          <div class="card-details-content">
            <div class="card-details-content-item-wrapper header">
              <div class="card-details-content-item">Talent</div>
              <div class="card-details-content-item" [matTooltip]="tooltips.visual_gender">Gender</div>
              <div class="card-details-content-item" [matTooltip]="tooltips.visual_age">Age</div>
              <div class="card-details-content-item" [matTooltip]="tooltips.ethnicity">Facial feat.</div>
              <div class="card-details-content-item" [matTooltip]="tooltips.role">Role</div>
              <div *ngIf="viewContext == 'assets'" class="card-details-content-item" matTooltip="Open talent tracker">&nbsp;</div>
              <div *ngIf="viewContext == 'assets'" class="card-details-content-item" matTooltip="Edit talent image">&nbsp;</div>
            </div>
            <div *ngFor="let talent of asset.talentImages" class="card-details-content-item-wrapper" (click)="onTalentClick(talent.talentId, talent.id)">
              <div class="card-details-content-item">
                <img *ngIf="talent && talent.talentId" class="card-details-content-item-img" [src]="talent.imageUrl" alt="" [matTooltip]="'Talent ID: ' + talent.talentId">
              </div>

              <div class="card-details-content-item">
                <span>{{talent.gender}}</span>
              </div>

              <div class="card-details-content-item">
                <span>{{talent.estimatedAge}}</span>
              </div>

              <div class="card-details-content-item">
                <span>{{talent.domFacialFeatures}}</span>
              </div>
              
              <div class="card-details-content-item upper">
                <span>{{talent.role == 'unknown' ? '-' : talent.role }}</span>
              </div>

              <div *ngIf="viewContext == 'assets'" class="card-details-content-item">
                <button type="button" class="card-details-content-item-button-action" (click)="onTalentTrackerClick($event, talent.talentId, talent.id)">
                  <mat-icon>play_arrow</mat-icon>
                </button>
              </div>

              <div *ngIf="viewContext == 'assets'" class="card-details-content-item">
                <button type="button" class="card-details-content-item-button-action" (click)="onEditTalentClick($event, talent.talentId, talent.id)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Edit mode -->
    <ng-container *ngIf="edit && (authorization.isEditor$ | async)">
      <div class="card-details">
        <!-- TAGS -->

        <span class="card-details-title">Tags</span>
        <p class="card-details-description">You can search for all existing tags to add them to the asset.</p>
        
        <mat-form-field appearance="fill" class="search-tags">
          <mat-label>Tag name</mat-label>
          <input type="text" matInput [formControl]="searchTagControl" [matAutocomplete]="auto" placeholder="Search tag">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='onTagSelected($event.option.value)'>
            <mat-option *ngFor="let option of filteredTags | async " [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        
        <ng-container *ngIf="tags.length > 0">
          <div class="card-details-content-wrapper">
            <div class="card-details-content">
              <div class="card-details-content-item-wrapper header">
                <div class="card-details-content-item">Tag name</div>
                <div class="card-details-content-item"></div>
              </div>
              <div *ngFor="let tag of tags" class="card-details-content-item-wrapper" > 
                <div class="card-details-content-item tag-name">
                  {{tag.name}}
                </div>
                <div class="card-details-content-item filler"></div>
                <div class="card-details-content-item">
                  <button mat-flat-button class="card-details-content-item-button" (click)="removeTagFromAsset(tag)">Remove</button>
                </div>
              </div> 
            </div>
          </div>
        </ng-container>
      </div>
      
      <div class="card-danger">
        <span class="card-danger-title">Delete Asset</span>
        <div class="card-danger-wrapper">
          <div>
            Once you delete an asset, this cannot be undone. Please be certain.
          </div>
          <button mat-flat-button class="form-button delete" (click)="delete()" [disabled]="loading">
            <mat-icon class="form-button-icon">delete</mat-icon>
            <span class="form-button-text">DELETE</span>
          </button>
        </div>
      </div>
    </ng-container>

  </div>

</div>

<no-kno-edit-buttons *ngIf="edit && (authorization.isEditor$ | async)" (saved)="save()" (canceled)="onCancel()" [loading]="loading"></no-kno-edit-buttons>
