<div *ngFor="let talent of [ talent ]" class="card-wrapper">
  <div class="card" *ngIf="(talent && !isLoading) else notalent">
    <div class="card-header">
      <span class="card-header-title">{{talent.id}}</span>

      <div class="card-header-buttons">
        <button *ngIf="(authorization.isEditor$ | async) && (viewContext=='assets')" type="button" class="card-header-buttons-button play" [disabled]="isLoading" (click)="onTalentTrackerClick($event, talent.id!, extraItemInfo!.assetId!)">
          <mat-icon class="card-header-buttons-button-icon">play_arrow</mat-icon>
        </button>
        <button *ngIf="authorization.isEditor$ | async" type="button" class="card-header-buttons-button edit" [disabled]="isLoading" (click)="edit = !edit">
          <mat-icon class="card-header-buttons-button-icon">edit</mat-icon>
        </button>
        <button type="button" class="card-header-buttons-button" (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <!-- <button class="card-image-wrapper" (click)="onImageClick()">
      <img [src]="talent.imageUrl" class="card-image" alt="">
      <span class="card-image-text">View Talent</span>
    </button> -->

    <no-kno-loader *ngIf="talent.images.length < 1 && isLoading" minHeight="160px"></no-kno-loader>
    
    <div class="card-carousel" *ngIf="talent.images.length">
      <mat-carousel
        #carousel 
        timings="250ms ease-in"
        color="accent"
        maxWidth="auto"
        [hideArrows]="false"
        [hideIndicators]="false"
        [useKeyboard]="true"
        [useMouseWheel]="false"
        [autoplay]="false"
        orientation="ltr"
        [maintainAspectRatio]="false"
        [lazyLoad]="true"
      >
        <mat-carousel-slide
          *ngFor="let image of talent.images; let i = index;"
          #matCarouselSlide
          [image]="image.imageUrl"
          [hideOverlay]="true"
          [load]="true"
          class="card-carousel-image"
        >
          <button *ngIf="!edit" class="card-carousel-image-button" (click)="onImageClick(i)">
            <span class="card-carousel-image-button-text">View Talent</span>
          </button>

          <button *ngIf="edit && (authorization.isEditor$ | async)" [class.main]="image === mainImage" class="card-carousel-image-button" (click)="selectMainImage(image)">
            <span class="card-carousel-image-button-text underline">Set as main image</span>
          </button>
        </mat-carousel-slide>
      </mat-carousel>
    </div>

    <ng-container *ngIf="!edit">
      
      <div *ngIf="talent.reviewStatus == ReviewStatus.WaitingForReview" class="card-review">
        <div class="card-review-header">
            <mat-icon>warning</mat-icon>
            <span>Review needed</span>
            <div class="card-review-header-filler"></div>
            <button class="card-review-header-button" [matMenuTriggerFor]="quickmenu">Quick review</button>
            <mat-menu #quickmenu="matMenu">
              <button mat-menu-item *ngFor="let status of quickReviewOptions" (click)="onQuickReviewClick(status.code)">{{ status.label }}</button>
            </mat-menu>
        </div>
        <div class="card-review-content">
          <ul class="card-review-content-list">
            <li *ngFor="let message of reviewMessages">{{ message }}</li>
          </ul>
        </div>
      </div>
      
      <table class="card-table">
        <tr class="card-table-row">
          <td class="card-table-cell title">Attribute</td>
          <td class="card-table-cell title">Value</td>
          <td class="card-table-cell title">Conf.</td>
        </tr>

        <tr class="card-table-row">
          <td class="card-table-cell title">
            <div class="centered">
              <review-status [talent]="talent" [reviewCode]="ReviewCode.GenderConfidence"></review-status>
              <span>Visual gender</span>
            </div>
          </td>
          <td class="card-table-cell">{{talent.gender}}</td>
          <td class="card-table-cell">{{talent.genderConfidence}}%</td>
        </tr>

        <tr class="card-table-row">
          <td class="card-table-cell title">
            <div class="centered">
              <review-status [talent]="talent" [reviewCode]="ReviewCode.AgeConfidence"></review-status>
              <span>Visual age</span>
            </div>
          </td>
          <td class="card-table-cell">{{talent.estimatedAge}}</td>
          <td class="card-table-cell">{{talent.minimumAge}}-{{talent.maximumAge}}</td>
        </tr>

        <tr class="card-table-row">
          <td class="card-table-cell title">
            <div class="centered">
              <review-status [talent]="talent" [reviewCode]="ReviewCode.EthnicityConfidence"></review-status>
              <span>Dominant facial features</span>
            </div>
          </td>
          <td class="card-table-cell">{{talent.domFacialFeatures}}</td>
          <td class="card-table-cell">{{talent.domFacialFeaturesConfidence}}%</td>
        </tr>

        <tr class="card-table-row">
          <td class="card-table-cell title">
            <div class="centered">
              <review-status [talent]="talent" [reviewCode]="ReviewCode.EthnicityConfidence"></review-status>
              <span>Secondary facial features</span>
            </div>
          </td>
          <td class="card-table-cell">{{talent.secFacialFeatures}}</td>
          <td class="card-table-cell">{{talent.secFacialFeaturesConfidence}}%</td>
        </tr>

      </table>

      <table class="card-table">
        <tr class="card-table-row">
          <td class="card-table-cell title">&nbsp;</td>
          <td class="card-table-cell title">&nbsp;</td>
        </tr>
        <tr class="card-table-row">
          <td class="card-table-cell title">
            <div class="centered">
              <span>Role</span>
            </div>
          </td>
          <td class="card-table-cell upper" *ngIf="viewContext=='assets'">{{ extraTalentAttributes.role }} </td>
          <td class="card-table-cell upper" *ngIf="viewContext!='assets'">{{ talent.roles | roles }} </td>
        </tr>
      </table>

      <ng-container *ngIf="assets && assets.length > 0">
        <div class="card-details">
          <span class="card-details-title">Assets</span>

          <div class="card-details-content-wrapper">
            <div class="card-details-content">
              <div class="card-details-content-item-wrapper header">
                <div class="card-details-content-item">Asset</div>
                <div class="card-details-content-item">Market</div>
                <div class="card-details-content-item">Channels</div>
                <div class="card-details-content-item">Views</div>
              </div>
              <div *ngFor="let asset of assets" class="card-details-content-item-wrapper" (click)="onAssetClick(asset.assetId)"> 
                <div class="card-details-content-item">
                  <img class="card-details-content-item-img" [src]="asset.assetThumbnailUrl" alt="" [matTooltip]="'Asset ID: ' + asset.assetId">
                </div>

                <div class="card-details-content-item">
                  <span *ngIf="asset.marketName; else noMarket">{{asset.marketName}}</span>
                  <ng-template #noMarket>-</ng-template>
                </div>

                <div class="card-details-content-item">
                  <div class="channel">
                    <ng-container *ngFor="let channel of asset.channels">
                      <svg-icon [src]="'/assets/svg/icon-' + channel.type.replace('channel', '') + '.svg'" class="channel-icon"
                          [matTooltip]="channel.type | channel"></svg-icon>                         
                    </ng-container>
                  </div>
                </div>

                <div class="card-details-content-item">
                  <span *ngIf="asset.views > 0; else nAn">
                    {{asset.views | views}}
                  </span>
                  <ng-template #nAn>-</ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="lookalikes && lookalikes.length > 0">
        <div class="card-details">
          <span class="card-details-title">Lookalikes</span>

          <div class="card-details-content-wrapper">
            <div class="card-details-content">
              <div class="card-details-content-item-wrapper header">
                <div class="card-details-content-item">Talent</div>
                <div class="card-details-content-item">Gender</div>
                <div class="card-details-content-item">Age</div>
                <div class="card-details-content-item">Facial feat.</div>
              </div>
              
              <div *ngFor="let lookalike of lookalikes" class="card-details-content-item-wrapper lookalike" > 
                <div class="card-details-content-item">
                  <img class="card-details-content-item-img" [src]="lookalike.imageUrl" alt="" [matTooltip]="'Talent ID: ' + lookalike.talentId">
                </div>

                <div class="card-details-content-item">
                  <span *ngIf="lookalike.gender; else noGender">{{lookalike.gender}}</span>
                  <ng-template #noGender>-</ng-template>
                </div>

                <div class="card-details-content-item">
                  <span *ngIf="lookalike.estimatedAge; else noAge">{{lookalike.estimatedAge}}</span>
                  <ng-template #noAge>-</ng-template>
                </div>

                <div class="card-details-content-item">
                  <span *ngIf="lookalike.domFacialFeatures; else noFacialFeatures">{{lookalike.domFacialFeatures}}</span>
                  <ng-template #noFacialFeatures>-</ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="talent.extraInfo && (authorization.isDeveloper$ | async)">
        <div class="card-details">
          <span class="card-details-title">Extra info</span>
          <div class="card-details-content-wrapper">
            <div class="card-details-content pre-json">
              <pre>{{talent.extraInfo | json}}</pre>
            </div>
          </div>
        </div>
      </ng-container>
    
    </ng-container>

    <ng-container *ngIf="edit && (authorization.isEditor$ | async)">
      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Gender</mat-label>
          <mat-select [(ngModel)]="attributes.gender" [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
            <ng-container *ngFor="let gender of genders">
              <mat-option [value]="gender">{{gender}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-field confidence">
          <mat-label>Conf.</mat-label>
          <input matInput type="number" max="100" min="0" [(ngModel)]="attributes.genderConf"
            [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field age">
          <mat-label>Age</mat-label>
          <input matInput type="number" max="120" min="0" [(ngModel)]="attributes.age"
              [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Dominant facial features</mat-label>
          <mat-select [(ngModel)]="attributes.domFacialFeatures" [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
            <ng-container *ngFor="let ff of facialFeatures">
              <mat-option [value]="ff">{{ff}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-field confidence">
          <mat-label>Conf.</mat-label>
          <input matInput type="number" max="100" min="0" [(ngModel)]="attributes.domFacialFeaturesConfidence"
            [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Secondary facial features</mat-label>
          <mat-select [(ngModel)]="attributes.secFacialFeatures" [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
            <ng-container *ngFor="let ff of facialFeatures">
              <mat-option [value]="ff">{{ff}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-field confidence">
          <mat-label>Conf.</mat-label>
          <input matInput type="number" max="100" min="0" [(ngModel)]="attributes.secFacialFeaturesConfidence"
           [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
        </mat-form-field>
      </div>

      <div class="form-row" *ngIf="viewContext=='assets'">
        <mat-form-field appearance="fill" class="form-field role">
          <mat-label>Role</mat-label>
          <mat-select [(ngModel)]="extraTalentAttributes.role" [ngModelOptions]="{standalone: true}" [disabled]="isLoading">
            <ng-container *ngFor="let role of roles">
              <mat-option [value]="role.code">{{role.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="card-details">
        <span class="card-details-title">Lookalikes</span>
        
        <mat-form-field appearance="fill" class="search-talent">
        <mat-label>Search Talent ID</mat-label>
          <input matInput type="text" [(ngModel)]="search" (ngModelChange)="onSearchChange()">
          <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        
        <ng-container *ngIf="lookalikes.length > 0">
          <div class="card-details-content-wrapper">
            <div class="card-details-content">
              <div class="card-details-content-item-wrapper header">
                <div class="card-details-content-item edit-lookalike">Talent</div>
                <div class="card-details-content-item edit-lookalike">Gender</div>
                <div class="card-details-content-item edit-lookalike">Age</div>
                <div class="card-details-content-item edit-lookalike">Facial feat.</div>
                <div class="card-details-content-item edit-lookalike"></div>
              </div>
              
              <div *ngFor="let lookalike of lookalikes" class="card-details-content-item-wrapper lookalike" > 
                <div class="card-details-content-item edit-lookalike">
                  <img class="card-details-content-item-img" [src]="lookalike.imageUrl" alt="" [matTooltip]="'Talent ID: ' + lookalike.talentId">
                </div>

                <div class="card-details-content-item edit-lookalike">
                  <span *ngIf="lookalike.gender; else noGender">{{lookalike.gender}}</span>
                  <ng-template #noGender>-</ng-template>
                </div>

                <div class="card-details-content-item edit-lookalike">
                  <span *ngIf="lookalike.estimatedAge; else noAge">{{lookalike.estimatedAge}}</span>
                  <ng-template #noAge>-</ng-template>
                </div>

                <div class="card-details-content-item edit-lookalike">
                  <span *ngIf="lookalike.domFacialFeatures; else noFacialFeatures">{{lookalike.domFacialFeatures}}</span>
                  <ng-template #noFacialFeatures>-</ng-template>
                </div>

                <div class="card-details-content-item edit-lookalike">
                  <div class="button-column">
                    <button mat-flat-button class="card-details-content-item-button" [matTooltip]="tooltips.merge_as_child" [disabled]="isLoading || lookalikesSelected.includes(lookalike)" (click)="mergeLookalike(lookalike, LookalikeMergeDirection.MergeAsChild)">Merge child</button>
                    <button mat-flat-button class="card-details-content-item-button" [matTooltip]="tooltips.merge_as_parent" [disabled]="isLoading || lookalikesSelected.includes(lookalike)" (click)="mergeLookalike(lookalike, LookalikeMergeDirection.MergeAsParent)">Merge parent</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="card-danger">
        <span class="card-danger-title">Delete Talent</span>
        <div class="card-danger-wrapper">
          <div>
            Once you delete a talent, this cannot be undone. Please be certain.
          </div>
          <button mat-flat-button class="form-button delete" (click)="delete()" [disabled]="isLoading">
            <mat-icon class="form-button-icon">delete</mat-icon>
            <span class="form-button-text">DELETE</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<no-kno-edit-buttons *ngIf="edit && !isLoading && (authorization.isEditor$ | async)" (saved)="save()" (canceled)="onCancel()" [loading]="loading"></no-kno-edit-buttons>

<ng-template #notalent>
  <no-kno-loader  minHeight="160px"></no-kno-loader>
</ng-template>
