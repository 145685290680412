import { Injectable } from "@angular/core";
import { UserRole } from "@no-kno/core/models/user.model";
import { AuthenticationService } from '@no-kno/modules/restricted/services/authentication.service';
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable()
export class AuthorizationService {

  isAdmin$ = new BehaviorSubject<boolean>(false);
  isDeveloper$ = new BehaviorSubject<boolean>(false);
  isEditor$ = new BehaviorSubject<boolean>(false);
  isViewer$ = new BehaviorSubject<boolean>(false);

  private subscription!: Subscription;

  constructor(
    public auth: AuthenticationService,
  ) {
    
    this.subscription = this.auth.user$.subscribe(user => {
      this.isAdmin$.next(user?.roles?.includes(UserRole.Admin) ?? false);
      this.isDeveloper$.next(user?.roles?.includes(UserRole.Developer) ?? false);
      this.isEditor$.next(user?.roles?.includes(UserRole.Editor) ?? false);
      //this.isViewer$.next(user?.roles?.includes(UserRole.Viewer) ?? false);
      this.isViewer$.next(user ? true: false);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}