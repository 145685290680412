import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReviewCode, ReviewStatus, Talent } from '@no-kno/core/models/talent.model';


@Component({
    selector: 'review-status',
    templateUrl: './review-status.component.html',
    styleUrls: ['./review-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewStatusComponent {

  readonly ReviewStatus = ReviewStatus;
  
  @Input() talent!: Talent;
  @Input() reviewCode?: ReviewCode;

  constructor(
  ) {}

}
