import { DatabaseDTOItem, DatabaseItem } from './database.model';

export interface Channel extends DatabaseItem {
  name: string;
  type: ChannelType;
  customerId: number;
}

export interface ChannelDTO extends DatabaseDTOItem {
  name: string;
  type: ChannelType;
  customer_id: number;
}

export enum ChannelType {
  Youtube = 'youtubechannel',
  Facebook = 'facebookchannel',
  Instagram = 'instagramchannel',
  AudienceNetwork = 'audiencenetworkchannel',
  Messenger = 'messengerchannel',
  CustomDataSource = 'custom_data_source',
  Website = 'website',
  FacebookOrganic  = 'facebook_organic',
  InstagramOrganic = 'instagram_organic',
  Email = 'email',
  Television = 'television',
  OnlineVideo = 'online_video',
  Print = 'print',
  YoutubeAds = 'youtube_ads',
  GoogleDisplayNetwork = 'google_display_network',
  Programmatic = 'programmatic',
  LinkedInAds = 'linkedin_ads',
  LinkedInOrganic = 'linkedin_organic',
};
