import { Component, ChangeDetectionStrategy, Optional, Inject, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'no-kno-cookies-dialog',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesDialogComponent implements OnInit{

  cookies = false;
  terms = false;

  constructor(
    public dialog: MatDialog,
    @Optional() private reference: MatDialogRef<CookiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.cookies = this.data;
      this.terms = this.data;
    }
  }

  onClose(value: boolean) {
    this.reference.close(value);
  }
}
