<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">{{data.title || ''}}</h1>
  </div>

  <mat-dialog-content class="dialog-content">
    <div class="dialog-content-text">
      {{data.text || ''}}
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button mat-flat-button mat-dialog-close class="actions-button" (click)="onClose(true)">
      <mat-icon class="button-icon">done</mat-icon>
      <span class="button-text">ACCEPT</span>
    </button>

    <button mat-flat-button mat-dialog-close class="actions-button-cancel" (click)="onClose(false)">
      <mat-icon class="button-icon">close</mat-icon>
      <span class="button-text">CLOSE</span>
    </button>
  </mat-dialog-actions>
</div>