import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Asset, AssetPreviewRequest } from '@no-kno/core/models/asset.model';
import { TalentPreviewRequest } from '@no-kno/core/models/talent.model';
import { PreviewService } from '@no-kno/core/services/preview.service';
import { DataService } from '@no-kno/modules/restricted/services/data.service';
import { FilterService } from '@no-kno/modules/restricted/services/filter.service';
import { Subscription, firstValueFrom } from 'rxjs';

enum PreviewMode {
  TALENT = 'talent',
  ASSET = 'asset',
  NONE = 'none'
}

@Component({
  selector: 'no-kno-entity-preview',
  templateUrl: './entity-preview.component.html',
  styleUrls: ['./entity-preview.component.scss']
})
export class EntityPreviewComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public  mode: PreviewMode = PreviewMode.NONE;
  PreviewMode = PreviewMode;
  public talentData: TalentPreviewRequest| null = null;
  public assetData: { asset: Asset }| null = null;
  public trackingInfo: { assetId: string, talentId: string }| null = null;
  public title: string = '';
  public isLoading: boolean = false;
  public isShowTracker: boolean = false;


  constructor(
    private previewService:PreviewService,
    private filterService: FilterService,
    private dataService: DataService,
    private detector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.subscription.add(this.previewService.request$.subscribe(async(data: TalentPreviewRequest|AssetPreviewRequest|null) => {
      this.talentData = null;
      this.assetData = null;
      this.title = '';
      this.isShowTracker = false;
      this.trackingInfo = null;
      console.log('preview data', data);
      if(data) {
        if(data.hasOwnProperty('talentId')) {
          this.mode = PreviewMode.TALENT;
          this.talentData = data as TalentPreviewRequest;
          this.title = `Talent ${this.talentData.talentId}`;
        } else {
          this.mode = PreviewMode.ASSET;
          const apr = data as AssetPreviewRequest;
          const asset =  await firstValueFrom(this.dataService.getAssetById(apr.assetId));
          this.assetData = {asset}
          this.title = `Asset ${asset.id}`;
          if(apr.track){
            this.trackingInfo = apr.track;
            this.isShowTracker = true;
            this.title = `Tracking asset ${asset.id}`;
          }
        }
      } else {
        this.mode = PreviewMode.NONE;
      }
      console.log('ending preview data', data);

      this.isLoading = false;
      this.detector.markForCheck();
    }));
  }

  onClose(): void {
    this.previewService.clearPreview();
    this.filterService.toggleFilterVisibility();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
