import { Injectable } from '@angular/core';
import { collectionData, docData, Firestore } from '@angular/fire/firestore';

import { collection, doc, DocumentData } from 'firebase/firestore';

import { Observable } from 'rxjs';


@Injectable()
export class FirebaseService {

  constructor(
    private firestore: Firestore
  ){
  }

  getCollection(path: string): Observable<any[]> {
    const ref = collection(this.firestore, path);

    return collectionData(ref, {idField: 'id'});
  }

  getDocument(path: string): Observable<DocumentData> {
    const ref = doc(this.firestore, path);

    return docData(ref);
  }

}
