import { DatabaseItem } from '@no-kno/core/models/database.model';

export const normalize = <T extends DatabaseItem>(object: T, edition: boolean = false): T => {
  const copy = { ...object };
  const now = new Date();

  copy.updated = now;

  delete copy.id;

  if (edition) {
    delete copy.created;
  } else {
    copy.created = now;
  }

  return copy;
};


