import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Asset, AssetTrackingInfo, AssetTypes, BoundingBox, BoxDictionary, getAssetTypeFromGivenType } from '@no-kno/core/models/asset.model';
import { RoleColors, TalentRole, TalentSelected, getRoleColor } from '@no-kno/core/models/talent.model';
import { ApiService } from '@no-kno/modules/restricted/services/api.service';
import { TalentSelectionService } from '@no-kno/modules/restricted/services/talent-selection.service';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';

type StringDictionary = {
  [key: string]: string;
};

@Component({
  selector: 'no-kno-asset-tracker',
  templateUrl: './asset-tracker.component.html',
  styleUrls: ['./asset-tracker.component.scss']
})
export class AssetTrackerComponent implements OnInit, OnDestroy {

  isVideo = false;
  @Input() data!: Asset;
  @Input() trackingInfo! : { assetId: string, talentId: string };
  subscription = new Subscription();
  image!: HTMLImageElement;
  video!: HTMLVideoElement;
  private containerDiv!: HTMLDivElement;
  trackingData!: AssetTrackingInfo;
  containerWidth = 0;
  private scaleFactor = 1;
  personRoles: StringDictionary = {};
  isLoading = false;
  readonly talentLabelPrefix = 'Talent ';
  private currentBoxes: BoxDictionary = {};
  isVideoReady = false;
  videoCurrentTime = 0;
  errorMessage = '';

  @ViewChild("canvasRef", { static: false }) canvasRef!: ElementRef;
  private context!: CanvasRenderingContext2D;
  private canvasElement: any;
  @ViewChild("videoRef", { static: false }) videoRef!: ElementRef;
  @ViewChild("containerRef", { static: false }) containerRef!: ElementRef;

  constructor(
    private api: ApiService,
    private talentSelectionService: TalentSelectionService,
    private detector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.isVideo = getAssetTypeFromGivenType(this.data.type) === AssetTypes.Video;

    
    this.subscription.add(this.api.getAssetTrackingInfo(this.trackingInfo.assetId).subscribe(trackingData => {
      if(trackingData){
        this.errorMessage = '';

        this.canvasElement = this.canvasRef.nativeElement;
        this.canvasElement.removeEventListener('click', this.onBoundingBoxClicked);
        this.canvasElement.addEventListener('click', this.onBoundingBoxClicked);
        this.context = this.canvasElement.getContext("2d");

        this.containerDiv = this.containerRef.nativeElement;
        this.containerWidth = this.containerDiv.clientWidth * 0.98;
        console.log('container width:', this.containerWidth);

        this.trackingData = trackingData;
        //console.log('tracking info:', trackingData);
        this.prepareData(trackingData);

        if(!this.isVideo){
          console.log('dealing with an image');
          this.loadImage(this.data.viewerUrl);
        } else {
          console.log('dealing with a video');
          this.loadVideo(this.data.viewerUrl);
        }
      } else {
        this.errorMessage = 'No tracking data found';
        console.error('no tracking data found');
        this.isLoading = false;
      }
    }));
  }

  private onBoundingBoxClicked = async (e:MouseEvent) => {
    //@ts-ignore
    var rect = e.target?.getBoundingClientRect();
    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };

    let talentId = '';
    for(const key in this.currentBoxes){
      const box = this.currentBoxes[key];
      console.log('checking box:', box, pos);
      if(pos.x >= box.left && pos.x <= box.left + box.width && pos.y >= box.top && pos.y <= box.top + box.height){
        console.log('talent selected:', key);
        talentId = key;
        break;
      }
    }

    if(talentId){
      const role = this.personRoles[talentId];
      const data:TalentSelected = {talentId, action: 'edit', role};
      this.talentSelectionService.selectTalentImage(data);
    } else {
      console.log('no talent selected');
      if(this.isVideo){
        this.video.paused ? await this.video.play() : this.video.pause();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.canvasElement){
      this.canvasElement.removeEventListener('click', this.onBoundingBoxClicked);
    }
  }

  onVideoTimeUpdate(e: any): void {
    this.videoCurrentTime = this.video.currentTime;
  }

  private prepareData = (data: AssetTrackingInfo) => {
    this.personRoles = {};
    data.tracking.forEach((person) => {
      const f = this.data.talentImages.find((t) => t.talentId === person.id.toString());
      if(person.id){
        this.personRoles[person.id] = f?.role || TalentRole.Unknown;
      }
    });
  }
  
  private lockCanvasSize = (w:number, h:number) => {
    const maxHeight = window.innerHeight * 0.6;
    console.log('max height:', maxHeight);

    // Calculate the scale factor for both dimensions
    const widthScale = this.containerWidth / w;
    const heightScale = maxHeight / h;

    // Use the smaller scale factor to ensure the image fits within the constraints
    this.scaleFactor = Math.min(widthScale, heightScale);

    // Calculate the new dimensions
    w = w * this.scaleFactor;
    h = h * this.scaleFactor;

    this.canvasElement.width = w;
    this.canvasElement.height = h;

    return  { w, h };
  }

  private loadVideo = (src: string) => {
    this.video = this.videoRef.nativeElement;
    this.video.load();

    this.video.oncanplaythrough = () => {
      if(this.isVideoReady){
        return
      }
      this.isVideoReady = true;
      console.log('video fully loaded:', this.video.videoHeight, this.video.videoWidth);
      let h = this.video.videoHeight;
      let w = this.video.videoWidth;
      
      ({w, h} = this.lockCanvasSize(w, h));
      this.video.width = w;
      this.video.height = h;
      
      this.isLoading = false;
      this.detector.markForCheck();
      
      this.video.play();
    };
  }

  private loadImage = (src: string) => {
    this.image = new Image();
    this.image.src = src;
    this.image.onload = () => {
      let w = this.image.width;
      let h = this.image.height;
      
      ({w, h} = this.lockCanvasSize(w, h));
      
      this.context.drawImage(this.image, 0, 0, w, h);
      
      this.trackingData.tracking.forEach((person) => {
        const role = this.personRoles[person.id];
        const label = person.id + ' - ' + role;
        const box = this.drawBoundingBox(person.appearances[0].bounding_box, this.image.width, this.image.height, label, getRoleColor(role));
        this.currentBoxes[person.id] = box;
      });

      this.isLoading = false;

      this.detector.markForCheck();
    };
  }

  private drawBoundingBox = (box:BoundingBox, w:number, h:number, label: string, color:string = 'red') => {
    
    this.context.beginPath();
    this.context.lineWidth = 2;
    this.context.strokeStyle = color;

    const left = Math.round(box.left * w * this.scaleFactor);
    const top = Math.round(box.top * h * this.scaleFactor);
    const width = Math.round(box.width * w * this.scaleFactor);
    const height = Math.round(box.height * h * this.scaleFactor);

    this.context.rect(left, top, width, height);
    this.context.stroke();

    this.context.fillStyle = color;
    this.context.fillRect(left, top - 16 , width, 16);

    this.context.font = "10px Arial";
    this.context.fillStyle = "white";
    this.context.fillText(label, left + 5, top - 5);

    return { left, top, width, height };
  }


  private videoTimerCallback = () => {
    if (this.video.paused || this.video.ended) {
      return;
    }
    
    this.computeVideoFrame();
    
    setTimeout(() => {
      this.videoTimerCallback();
    }, 200);
  };

  private computeVideoFrame = () => {

    const w = this.canvasElement.width;
    const h = this.canvasElement.height;
    //this.context.drawImage(this.video, 0, 0, w, h);
    this.context.clearRect(0, 0, w, h);
    const currentPosition = this.video.currentTime;
    const minTime = (currentPosition * 1000) - 150;
    const maxTime = (currentPosition * 1000) + 150;
    
    const updatedBoxes:BoxDictionary = {};
    this.trackingData.tracking.forEach((person) => {
      const s = person.appearances.find((app) => app.timestamp >= minTime && app.timestamp <= maxTime);
      if(s){
        const role = this.personRoles[person.id];
        const label = person.id + ' - ' + role;
        const box = this.drawBoundingBox(s.bounding_box, this.video.videoWidth, this.video.videoHeight, label, getRoleColor(role));
        updatedBoxes[person.id] = box;
      }
    });
    this.currentBoxes = updatedBoxes;
  };

  private async pauseVideoAtTime(seconds: Number): Promise<void>{
    this.video.currentTime = seconds as number;
    await this.video.play();
    this.videoTimerCallback();
    this.video.pause();
  }

  async onTimelineItemSelected(timelineBox:any): Promise<void> {
    console.log('onTimelineItemSelected', timelineBox);
    const talentId = timelineBox.talentId;
    const startSeconds = timelineBox.start;

    const role = this.personRoles[talentId];
    const data:TalentSelected = {talentId, action: 'edit', role};
    this.talentSelectionService.selectTalentImage(data);

    if(this.isVideo){
      await this.pauseVideoAtTime(startSeconds);
    }
  }

  async onTimelineClick(secs:Number): Promise<void>{
    console.log('onTimelineClick', secs);
    if(this.isVideo){
      await this.pauseVideoAtTime(secs);
    }
  }

  onVideoPlayed(): void {
    this.videoTimerCallback();
  }

  onVideoPaused(): void {
    console.log('video paused');
  }

  onVideoEnded(): void {
    console.log('video ended');
    const w = this.canvasElement.width;
    const h = this.canvasElement.height;
    this.context.clearRect(0, 0, w, h);
  }

}
