import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TalentImage } from '@no-kno/core/models/talent.model';
import { MatCarouselComponent } from 'ng-mat-carousel';

@Component({
  selector: 'no-kno-talent-preview',
  templateUrl: './talent-preview.component.html',
  styleUrls: ['./talent-preview.component.scss']
})
export class TalentPreviewComponent {

  @ViewChild('carousel') carousel!: MatCarouselComponent;
  @Input() data!: { images: TalentImage[]; imageIndex: number }

  constructor(private detector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.data.images.length && this.carousel) {
      this.carousel.slideTo(this.data.imageIndex);

      this.detector.markForCheck();
    }
  }

}
